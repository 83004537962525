<script lang="ts">
import { defineComponent, ref, watch, onMounted } from "vue";
import type { WorkImage } from "@/typings/api";
import { getWorkImage } from "@/api/catalogRichMedia";
import Visual from "@/components/ui/Visual.vue";

export default defineComponent({
  components: {
    Visual,
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const obj = ref<WorkImage | null>(null);
    const loadObj = async () => {
      obj.value = await getWorkImage(props.uid);
    };
    watch(() => props.uid, loadObj);
    onMounted(loadObj);
    return {
      obj,
    };
  },
});
</script>

<template>
  <div class="work-image">
    <Visual v-if="obj" :image="obj" :max-width="800" :animation="false" :max-ratio="1.5" />
    <span v-if="obj?.credits" class="credits" v-text="obj.credits" />
  </div>
</template>
