<script lang="ts">
import { computed, defineComponent, reactive, ref } from "vue";
import type { PropType } from "vue";
import type { WorkImage as Image } from "@/typings/api";
import settings from "@/settings";
import { useElementBounding } from "@vueuse/core";

export default defineComponent({
  props: {
    image: {
      type: Object as PropType<Image>,
      required: true,
    },
    altText: {
      type: String,
      default: "Visual",
    },
    maxRatio: {
      type: Number,
      default: null,
    },
    maxWidth: {
      type: Number,
      default: 2000,
    },
    resize: {
      type: String,
      default: "crop",
    },
    lazy: {
      type: Boolean,
      default: true,
    },
    animation: {
      type: Boolean,
      default: true,
    },
    duration: {
      type: Number,
      default: 500,
    },
  },
  emits: ["loading", "loaded", "error"],
  setup(props, { emit }) {
    const el = ref<HTMLElement | null>(null);
    const lazyLoaded = ref(true);
    const endpoint = settings.IMAGE_RESIZER_ENDPOINT;
    const ratio = computed(() => {
      return props.image?.ratio ?? 1;
    });

    const { width } = useElementBounding(el);

    const displayHeight = computed(() => {
      if (props.maxRatio) {
        return Math.min(
          Math.round(width.value / ratio.value),
          Math.round(width.value / props.maxRatio)
        );
      }
      return Math.round(width.value / ratio.value);
    });

    //image src creation
    const maxWidth = computed(() => {
      return props.maxWidth;
    });
    const maxHeight = computed(() => {
      return Math.round(maxWidth.value / ratio.value);
    });

    const src = computed(() => {
      // eg: /images/crop/80x80/catalog/work/AB7DD2F0/6308E497.jpg
      return `${endpoint}${props.resize}/${maxWidth.value}x${maxHeight.value}/${props.image?.path}`;
    });

    const backgroundColor = computed(() => {
      if (props.animation && props.lazy) {
        const bg = props.image?.rgb ?? [255, 255, 255];
        let bgOpacity = lazyLoaded.value ? 0 : 0.5;
        return `rgba(${bg.join(",")}, ${bgOpacity})`;
      }
      return `transparent`;
    });

    const lazyOptions = reactive({
      src,
      lifecycle: {
        loading: () => {
          lazyLoaded.value = false;
          emit("loading");
        },
        loaded: () => {
          lazyLoaded.value = true;
          emit("loaded");
        },
        error: () => {
          lazyLoaded.value = false;
          emit("error");
        },
      },
      observerOptions: { rootMargin: "0px", threshold: 0.5 },
    });

    return {
      el,
      src,
      displayHeight,
      lazyOptions,
      backgroundColor,
    };
  },
});
</script>

<template>
  <figure
    ref="el"
    class="visual"
    :style="{
      '--duration': `${duration}ms`,
      backgroundColor,
    }"
  >
    <img
      v-if="lazy"
      v-lazy="lazyOptions"
      class="lazy"
      :alt="altText"
      :style="{ height: `${displayHeight}px` }"
    />
    <img v-else :src="src" :alt="altText" :style="{ height: `${displayHeight}px` }" />
  </figure>
</template>

<style lang="scss" scoped>
.visual {
  margin: 0;
  max-height: 100%;
  max-width: 100%;
  display: block;
  width: 100%;
  height: auto;
  background-color: rgba(var(--c-bg));
  transition: background-color var(--duration) ease-in-out;

  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: 100%;

    &.lazy {
      opacity: 0;
      transition: opacity var(--duration) ease-in-out;

      &[lazy="loaded"] {
        opacity: 1;
      }
    }
  }
}
</style>
