<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import Button from "@/components/ui/button/Button.vue";
import IconClose from "@/components/ui/icon/IconClose.vue";
import { useI18n } from "vue-i18n";
import { refDebounced, useStorage } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { useCatalogStore } from "@/stores/catalog";
import { useUiStore } from "@/stores/ui";

const FOCUS_DEBOUNCE = 400;

export default defineComponent({
  components: {
    Button,
    IconClose,
  },
  props: {
    showTutorial: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const active = ref(false);
    const sessionState = useStorage("show-tutorial", true, sessionStorage);
    const { focusedWork } = storeToRefs(useCatalogStore());
    const work = refDebounced(focusedWork, FOCUS_DEBOUNCE);
    const audios = computed(() => work.value?.audios ?? []);
    const audio = computed(() => (audios.value.length ? audios.value[0] : null));
    const { displaySplash } = storeToRefs(useUiStore());

    const hide = () => {
      active.value = false;
    };

    watch(
      () => active.value,
      (value) => {
        if (!value) {
          sessionState.value = false;
        }
      }
    );

    watch(
      () => displaySplash.value,
      (value) => {
        if (!value) {
          setTimeout(() => {
            active.value = props.showTutorial;
          }, 1000);
        }
      }
    );

    const displayTutorial = computed(() => {
      return active.value && props.showTutorial && sessionState.value;
    });

    return {
      t,
      displayTutorial,
      audio,
      hide,
    };
  },
});
</script>

<template>
  <transition name="tutorial" :duration="500" appear>
    <div
      v-if="displayTutorial"
      class="tutorial"
      :class="{ 'has-audio': audio }"
      v-touch:swipe.top="hide"
      @wheel="hide"
    >
      <div class="close">
        <Button v-touch:tap="hide" class="primary is-icon">
          <IconClose :size="48" />
        </Button>
      </div>
      <div class="info info--story">
        <div>
          <span class="hint" v-text="`${t('tutorial.story')}`" />
        </div>
      </div>
      <div v-if="audio" class="info info--audio">
        <div>
          <span class="hint" v-text="`${t('tutorial.audio')}`" />
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@use "@/style/abstracts/responsive";
@use "@/style/elements/button";

.tutorial {
  --tutorial-height: calc(100 * var(--vh));

  &.has-audio {
    --tutorial-height: calc(100 * var(--vh) - var(--audio-player-height));
  }

  position: absolute;
  top: 0;
  left: 0;
  z-index: var(--tutorial-z-index);
  width: 100%;
  height: var(--tutorial-height);
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  .close {
    position: fixed;
    left: 50%;
    transform: translateX(-50%);
    top: var(--grid-gap);
    z-index: var(--tutorial-z-index);
  }

  &:after,
  &:before {
    content: "";
    position: absolute;
    left: 0;
    width: 100%;
    height: calc(25 * var(--vh));
    backdrop-filter: blur(10px);
    -webkit-backdrop-filter: blur(10px);
    z-index: -1;

    @include responsive.bp-large-up {
      height: 200px;
    }
  }

  &:before {
    top: 0;
  }
  &:after {
    bottom: 0;
  }

  .info {
    display: flex;
    align-items: center;
    flex-direction: column;

    max-width: var(--content-max-width);
    height: calc(25 * var(--vh));
    margin-left: auto;
    margin-right: auto;
    padding: 0 var(--grid-gap);

    &--story {
      margin-bottom: calc(-1 * var(--button-size));
    }

    &--audio {
      > div {
        margin-bottom: 0;
      }
    }

    > div {
      justify-content: center;
      display: flex;
      align-items: center;
      flex-direction: column;
      margin-top: auto;
    }

    .hint {
      @include button.primary;

      position: relative;
      text-transform: initial;
      text-align: center;
      display: inline-flex;
      background-color: rgb(var(--c-primary));
      border-radius: var(--border-radius);
      margin-bottom: var(--grid-gap);
      cursor: inherit;

      @include responsive.bp-medium-up {
        width: 50%;
      }

      &:not(:disabled) {
        @include responsive.on-hover {
          background: rgb(var(--c-primary));
          border: 1px solid rgb(var(--c-primary));
        }
      }

      &:after {
        content: "";
        position: absolute;
        border-style: solid;
        border-width: 30px 10px 0;
        border-color: rgb(var(--c-primary)) transparent;
        display: block;
        width: 0;
        z-index: 1;
        bottom: -30px;
        left: 50%;
        transform: translateX(-50%);
      }
    }

    .sign {
      @include button.primary;
      @include button.icon;

      border-radius: 999rem;
      text-transform: lowercase;
      cursor: default;
    }
  }
}

/* tutorial transitions */
.tutorial-enter-active,
.tutorial-leave-active {
  > div {
    transition-property: opacity, transform;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }

  &:before,
  &:after {
    transition-property: background-color, backdrop-filter, -webkit-backdrop-filter;
    transition-duration: 250ms;
    transition-timing-function: ease-in-out;
  }
}

.tutorial-leave-active {
  &:before,
  &:after {
    transition-delay: 250ms;
  }
}

.tutorial-enter-from,
.tutorial-leave-to {
  > div {
    opacity: 0;
    transform: translateY(-20px) scale(0.25);
  }

  &:before,
  &:after {
    backdrop-filter: blur(10px) opacity(0);
    -webkit-backdrop-filter: blur(10px) opacity(0);
  }
}

.tutorial-enter-to {
  > div {
    transform: translateY(0) scale(1);
    opacity: 1;
  }

  &:before,
  &:after {
    backdrop-filter: blur(10px) opacity(1);
    -webkit-backdrop-filter: blur(10px) opacity(1);
  }
}
</style>
