<script lang="ts">
import { defineComponent } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  setup() {
    const { t } = useI18n();
    return {
      t,
    };
  },
});
</script>

<template>
  <section class="page">
    <div class="content">
      <header>
        <h1 class="title" v-text="`${t('page.imprint.title')}`" />
      </header>
      <section>
        <div class="content-block competence competence--content">
          <div>
            <h2 class="subtitle" v-text="t('page.imprint.subtitle-content')" />
          </div>
          <div>
            <p class="address">
              <span v-text="t('cfb.address.name')" />
              <span v-text="t('cfb.address.department-digital')" />
              <span v-text="t('cfb.address.street')" />
              <span v-text="t('cfb.address.po') + ' ' + t('cfb.address.city')" />
              <span v-text="t('cfb.address.country')" />
            </p>
            <a href="mailto:kommunikation@fondationbeyeler.ch">kommunikation@fondationbeyeler.ch</a>
          </div>
        </div>
        <div class="content-block competence competence--design">
          <div>
            <h2 class="subtitle" v-text="t('page.imprint.subtitle-design')" />
          </div>
          <div>
            <a href="https://raffinerie.com/">Raffinerie AG</a>
          </div>
        </div>
        <div class="content-block competence competence--code">
          <div>
            <h2 class="subtitle" v-text="t('page.imprint.subtitle-code')" />
          </div>
          <div>
            <a href="https://pbi.io">Palmbeach Interactive GmbH</a>
          </div>
        </div>
        <div class="content-block competence competence--photography">
          <div>
            <h2 class="subtitle" v-text="t('page.imprint.subtitle-photography')" />
          </div>
          <div>
            <p v-text="t('page.imprint.content-photography')" />
          </div>
        </div>
        <div class="content-block competence competence--text">
          <div>
            <h2 class="subtitle" v-text="t('page.imprint.subtitle-text')" />
          </div>
          <div>
            <p>
              <span>Stefanie Bringezu</span>
              <span>Victoria Gellner</span>
              <span>Catherine Iselin</span>
              <span>Julian Mintert</span>
              <span>Janine Schmutz</span>
              <span>Esther Spycher</span>
            </p>
          </div>
        </div>
        <div class="content-block competence competence--editing">
          <div>
            <h2 class="subtitle" v-text="t('page.imprint.subtitle-editing')" />
          </div>
          <div>
            <p>
              <span>Amelie Baader</span>
              <span>Katrin Boskamp-Priever</span>
              <span>Romina Del Principe</span>
              <span>Catherine Iselin</span>
              <span>Julian Mintert</span>
              <span>Franziska Stegmann</span>
            </p>
          </div>
        </div>
        <div class="content-block competence competence--editing">
          <div>
            <p v-text="t('page.imprint.notes')" />
          </div>
        </div>
      </section>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@use "@/style/abstracts/responsive";
@use "@/style/base/typo";
@use "@/style/elements/link";

.page {
  * {
    @include typo.content-alt;
  }

  padding: var(--nav-height) var(--grid-gap);

  .content {
    margin: auto;
    max-width: var(--content-max-width);

    .title {
      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: var(--nav-height);
      text-transform: uppercase;
    }

    a {
      @include link.secondary;
    }
  }

  .content-block {
    display: flex;
    flex-direction: column;
    margin-bottom: calc(2 * var(--grid-gap));

    @include responsive.bp-large-up {
      display: grid;
      grid-template-columns: 1fr 1fr;
    }
    .subtitle {
      text-transform: uppercase;

      @include responsive.bp-large-up {
        text-transform: inherit;
      }
    }
  }

  .competence {
    p {
      span {
        display: flex;
      }
    }
  }
}
</style>
