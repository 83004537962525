<script lang="ts" setup>
import type { PropType } from "vue";
import type { Work } from "@/typings/api";
import { computed } from "vue";
import { useI18n } from "vue-i18n";

const props = defineProps({
  work: {
    type: Object as PropType<Work>,
    required: true,
  },
});
const { locale: i18nLocale } = useI18n();
const link = computed(() => {
  const locale = i18nLocale.value === "de" ? "" : i18nLocale.value;
  return {
    name: "workDetail",
    params: {
      locale: locale,
      uid: props.work.uid,
    },
  };
});
</script>

<template>
  <div class="work">
    <router-link :to="link" v-text="work.title" />
  </div>
</template>

<style lang="scss" scoped>
@use "@/style/base/typo";
@use "@/style/abstracts/responsive";

.work {
  display: flex;
  margin-bottom: calc(var(--grid-gap) / 2);
  @include typo.dim;

  @include responsive.bp-large-up {
    @include typo.dim(1);
  }
}
</style>
