<script lang="ts">
import { defineComponent } from "vue";
import { useSlots } from "vue";

export default defineComponent({
  props: {
    label: {
      type: String,
      default: "Button",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  setup() {
    const slots = useSlots();
    const blur = (el: any) => {
      el.target.blur();
    };
    return {
      slots,
      blur,
    };
  },
});
</script>

<template>
  <button ref="el" class="button" :disabled="disabled" v-touch:tap="blur">
    <span v-if="label && !slots.default" class="label" v-text="label" />
    <slot name="default" />
  </button>
</template>

<style lang="scss" scoped>
@use "@/style/elements/button";

.button {
  @include button.default;
  &.is-active {
    @include button.active;
  }
  &.invert {
    @include button.invert;
  }
  &.primary {
    @include button.primary;
  }
  &.is-icon {
    @include button.icon;
  }
}
</style>
