<script lang="ts">
import { computed, defineComponent, ref, onMounted, watch } from "vue";
import { useWindowSize } from "@vueuse/core";
// @ts-ignore
import Splitting from "splitting";

const TEXT =
  "Lorem ipsum dolor sit amet, consectetur adipiscing elit. Nullam dapibus erat quis bibendum accumsan. Sed vitae tellus mi. Cras libero massa, ullamcorper in eros in, fringilla lobortis metus. Mauris suscipit, nisl at consequat semper?";

export default defineComponent({
  setup() {
    const { width: vpWidth } = useWindowSize();
    const text = TEXT;
    const elMax = ref<HTMLElement | null>(null);
    const lines = ref([]);
    const styleMax = computed(() => {
      const wght = 800;
      return {
        fontVariationSettings: `"wght" ${wght}`,
      };
    });
    const updateLines = () => {
      // @ts-ignore
      const el = elMax.value.querySelector(".text-to-split");
      // @ts-ignore
      const before = elMax.value.innerHTML;

      const raw = Splitting({ target: el, by: "lines" });
      // @ts-ignore
      elMax.value.innerHTML = before;

      lines.value = raw[0].lines.map((l: Array<any>) => {
        return l.map((w: any) => w.innerHTML).join(" ");
      });
    };
    onMounted(() => {
      setTimeout(() => {
        updateLines();
      }, 100);
    });
    watch(
      () => vpWidth.value,
      () => {
        updateLines();
      }
    );
    return {
      text,
      elMax,
      styleMax,
      lines,
    };
  },
});
</script>

<template>
  <div class="ptype">
    <!--
    <div class="container">
      <div v-text="text" />
    </div>
    -->
    <div ref="elMax" :style="styleMax" class="container container--max">
      <div class="text-to-split" v-text="text" />
    </div>
    <div class="container container--result">
      <div v-for="(line, index) in lines" :key="`line-${index}`" v-text="line" />
    </div>
    <!-- -->
    <pre v-text="lines" />
  </div>
</template>

<style lang="scss" scoped>
.ptype {
  padding-top: 64px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  .container {
    background: #d4daff;
    max-width: 80vw;
    margin-bottom: 20px;
    text-align: center;
    padding: 8px;
  }
  pre {
    font-size: 12px;
  }
}
</style>
