<script lang="ts">
import { defineComponent, computed } from "vue";

const BASE_SIZE = 48;

export default defineComponent({
  props: {
    size: {
      type: Number,
      default: 24,
    },
    color: {
      type: String,
      default: "rgb(var(--c-page-fg))",
    },
  },
  setup(props) {
    const style = computed(() => {
      return {
        fill: props.color,
        //stroke: props.color,
        //strokeWidth: (2 * BASE_SIZE) / props.size,
        //strokeMiterlimit: 10,
        transform: `scale(${props.size / BASE_SIZE})`,
      };
    });
    return {
      style,
    };
  },
});
</script>
<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="48px"
    height="48px"
    viewBox="0 0 48 48"
    :style="style"
  >
    <g transform="translate(1.000000, 8.000000)" fill-rule="nonzero">
      <path
        d="M22.9991965,31.9999733 C17.9552524,32.004787 13.0367004,30.4351151 8.93547147,27.5117487 C4.88320178,24.6341447 1.77471214,20.6291506 0,15.9992413 C1.77462784,11.3692848 4.88313475,7.36426849 8.93547147,4.48673385 C17.3474397,-1.49557795 28.647739,-1.49557795 37.0597072,4.48673385 C41.1137616,7.36364501 44.2239833,11.3687097 46,15.9992413 C44.2266564,20.6320813 41.1180485,24.6400109 37.0645285,27.519749 C32.961891,30.4405905 28.0428072,32.0074549 22.9991965,31.9999733 L22.9991965,31.9999733 Z M22.9991965,5.33317115 C17.1577192,5.41220614 12.4822949,10.1831274 12.5466233,15.9992413 C12.4866934,21.8113942 17.1554418,26.5791501 22.9927681,26.6669114 C28.8364012,26.587872 33.5148502,21.8175487 33.4549838,15.9992413 C33.5139826,10.1840186 28.8396735,5.41570158 22.9991965,5.33317115 Z M22.9991965,22.4011234 C20.3855195,22.4661998 17.9926226,20.9487573 16.9467955,18.5630266 C15.9009684,16.1772959 16.4105752,13.3985949 18.2357539,11.5348039 C20.0609325,9.67101292 22.838046,9.09346189 25.2599612,10.0739928 C27.6818764,11.0545238 29.2660661,13.3977819 29.2668833,16.0008413 C29.3044264,19.4884465 26.5019623,22.3494823 22.9991965,22.3995233 L22.9991965,22.4011234 Z"
      ></path>
    </g>
  </svg>
</template>
