<script lang="ts">
import { defineComponent } from "vue";

export default defineComponent({
  props: {
    duration: {
      type: [Number, Object],
      default: 300,
    },
    enterDuration: {
      type: [Number, Object],
      default: null,
    },
    leaveDuration: {
      type: [Number, Object],
      default: null,
    },
  },
  emits: ["finished"],
  setup(props, { emit }) {
    const leave = (el: any) => {
      const { height } = getComputedStyle(el);
      /* eslint-disable no-param-reassign */
      el.style.height = height;

      // Force repaint to make sure the
      // animation is triggered correctly.
      // eslint-disable-next-line no-unused-expressions
      getComputedStyle(el).height;
      requestAnimationFrame(() => {
        el.style.height = "0";
      });
    };

    const setEnterDuration = (el: any) => {
      const transitionElement = el;
      let duration;
      duration = props.duration;
      if (props.enterDuration !== null) {
        duration = props.enterDuration;
      }
      transitionElement.style.transitionDuration = `${duration}ms`;
    };

    const setLeaveDuration = (el: any) => {
      const transitionElement = el;
      let duration;
      duration = props.duration;
      if (props.leaveDuration !== null) {
        duration = props.leaveDuration;
      }
      transitionElement.style.transitionDuration = `${duration}ms`;
    };

    const cleanUp = (el: any) => {
      el.style.transitionDuration = "";
      emit("finished", true);
    };

    const enter = (el: any) => {
      /* eslint-disable no-param-reassign */
      const { width } = getComputedStyle(el);

      el.style.width = width;
      el.style.position = "absolute";
      el.style.visibility = "hidden";
      el.style.height = "auto";

      const { height } = getComputedStyle(el);
      el.style.width = "";
      el.style.position = "";
      el.style.visibility = "";
      el.style.height = "0";

      // Force repaint to make sure the
      // animation is triggered correctly.
      // eslint-disable-next-line no-unused-expressions
      getComputedStyle(el).height;

      // Trigger the animation.
      // We use `requestAnimationFrame` because we need
      // to make sure the browser has finished
      // painting after setting the `height`
      // to `0` in the line above.
      requestAnimationFrame(() => {
        el.style.height = height;
      });
    };

    const afterEnter = (el: any) => {
      /* eslint-disable no-param-reassign */
      el.style.height = "auto";
      emit("finished", true);
      setLeaveDuration(el);
    };

    const beforeEnter = (el: any) => {
      setEnterDuration(el);
    };

    return {
      enter,
      leave,
      setEnterDuration,
      setLeaveDuration,
      beforeEnter,
      afterEnter,
      cleanUp,
    };
  },
});
</script>

<template>
  <transition
    appear
    name="expand"
    v-bind="$attrs"
    @before-enter="beforeEnter"
    @enter="enter"
    @after-enter="afterEnter"
    @leave="leave"
    @after-leave="cleanUp"
  >
    <slot />
  </transition>
</template>

<style lang="scss" scoped>
* {
  transform: translateZ(0);
  backface-visibility: hidden;
  perspective: 1000px;

  will-change: height;
}

.expand-enter-active,
.expand-leave-active {
  overflow: hidden;

  opacity: 1;

  transition-timing-function: ease-in-out;
  transition-property: opacity, height;
}

.expand-enter,
.expand-leave-to {
  height: 0;

  opacity: 0;
}
</style>
