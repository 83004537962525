<script lang="ts">
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import { useCatalogStore } from "@/stores/catalog";
import { useRouter } from "vue-router";
import Button from "@/components/ui/button/Button.vue";
import IconClose from "@/components/ui/icon/IconClose.vue";

export default defineComponent({
  components: {
    IconClose,
    Button,
  },
  setup() {
    const { t, locale: i18nLocale } = useI18n();
    const router = useRouter();
    const locale = computed(() => {
      // TODO: handle default language
      return i18nLocale.value === "de" ? "" : i18nLocale.value;
    });
    const { listMode: routeName } = storeToRefs(useCatalogStore());

    const navigate = () => {
      router.push({
        name: routeName.value,
        params: {
          locale: locale.value,
          transition: "reveal",
        },
      });
    };

    const onTap = () => {
      navigate();
    };

    return {
      t,
      locale,
      onTap,
    };
  },
});
</script>

<template>
  <div class="work-index-link">
    <Button v-touch:tap="onTap" class="is-icon">
      <IconClose :size="48" />
    </Button>
  </div>
</template>
