import { computed } from "vue";
import { useWindowSize, useBreakpoints } from "@vueuse/core";

const useDevice = () => {
  const breakpoints = useBreakpoints({
    small: 768,
    medium: 1024,
    large: 1400,
    xlarge: 1920,
  });
  const { width, height } = useWindowSize();
  const isMobile = computed(() => {
    return breakpoints.smaller("medium");
  });
  const isDesktop = computed(() => {
    return breakpoints.greater("medium");
  });
  const isSafari = computed(() => {
    // @ts-ignore
    return !!navigator.userAgent.match(/Version\/[\d.]+.*Safari/);
  });
  return {
    isMobile: isMobile.value,
    isDesktop: isDesktop.value,
    isSafari: isSafari.value,
    windowSize: { width, height },
  };
};

const useDeviceExtra = () => {
  document.documentElement.style.height = "100vh";

  //get mobile browser address bar height
  const scrollOffset = computed(() => {
    return (
      parseFloat(getComputedStyle(document.documentElement).height) -
      document.documentElement.clientHeight
    );
  });

  return {
    scrollOffset,
  };
};

export { useDevice, useDeviceExtra };
