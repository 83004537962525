<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { refAutoReset } from "@vueuse/core";

export default defineComponent({
  props: {
    progress: {
      type: Number,
      default: 0,
    },
    canSeek: {
      type: Boolean,
      default: true,
    },
  },
  emits: ["seek"],
  setup(props, { emit }) {
    const root = ref<HTMLElement | null>(null);
    const inputEl = ref<HTMLElement | null>(null);
    const hasFocus = ref(false);

    const seekPosition = ref(0);
    const isSeeking = refAutoReset(false, 200);

    const inputValue = computed(() => {
      if (isSeeking.value) {
        return seekPosition.value;
      } else {
        return props.progress * 100;
      }
    });

    const onInput = (e: Event) => {
      const value = Number((e.target as HTMLInputElement).value);
      isSeeking.value = true;
      seekPosition.value = value;
    };
    const onChange = (e: Event) => {
      const value = Number((e.target as HTMLInputElement).value);
      emit("seek", value * 0.01);
    };
    return {
      root,
      inputEl,
      hasFocus,
      isSeeking,
      inputValue,
      onInput,
      onChange,
    };
  },
});
</script>

<template>
  <div ref="root" class="playhead">
    <input
      ref="inputEl"
      @change="onChange"
      @input.self="onInput"
      type="range"
      min="0"
      max="100"
      step="0.1"
      :value="inputValue"
    />
    <div class="track" />
    <div
      class="progress"
      :style="{
        width: `${inputValue}%`,
      }"
    />
  </div>
</template>

<style lang="scss" scoped>
@use "@/style/abstracts/responsive";

@mixin range-input {
  --range-track-height: 1px;

  @include responsive.bp-large-up {
    --range-track-height: 2px;
  }

  input[type="range"] {
    //
    -webkit-appearance: none;
    appearance: none;
    background: transparent;
    cursor: pointer;
    height: 20px;
    width: 100%;
    &:focus {
      outline: none;
    }
    // track
    &::-webkit-slider-runnable-track {
      background-color: rgba(var(--c-track), 0);
      //margin-top: -10px;
      height: 20px;
    }
    &::-moz-range-track {
      background-color: rgba(var(--c-track), 0);
      height: var(--range-track-height);
    }
    // thumb
    &::-webkit-slider-thumb {
      -webkit-appearance: none;
      appearance: none;
      margin-top: 3px;
      background-color: rgb(var(--c-thumb));
      height: 12px;
      width: 12px;
      border-radius: 3px;
    }
    &::-moz-range-thumb {
      border: none;
      background-color: rgb(var(--c-thumb));
      height: 12px;
      width: 12px;
      border-radius: 3px;
    }
  }
}

.playhead {
  --c-thumb: 0, 0, 0;
  --c-track: 0, 0, 0;
  @include range-input;
  position: relative;
  height: 20px;
  margin-top: -1px;
  .track,
  .progress {
    background: black;
    width: 100%;
    position: absolute;
    left: 2px; // why??
    pointer-events: none;
  }
  .track {
    height: var(--range-track-height);
    top: 11px;

    @include responsive.bp-large-up {
      top: 10px;
    }
  }
  .progress {
    height: 4px;
    top: 9px;
    width: 50%;
  }
  @-moz-document url-prefix() {
    margin-top: -2px;
    .track,
    .progress {
      left: 0;
    }
    .track {
      top: 11px;
    }
    .progress {
      top: 10px;
    }
  }
}
</style>
