import type { WorkVideo, WorkText, WorkAudio, WorkImage } from "@/typings/api";
import { APIClient } from "@/api/client";
import settings from "@/settings";

const WORK_VIDEO_ENDPOINT = `${settings.API_BASE_URL}catalog/works/videos/`;
const WORK_TEXT_ENDPOINT = `${settings.API_BASE_URL}catalog/works/text/`;
const WORK_AUDIO_ENDPOINT = `${settings.API_BASE_URL}catalog/works/audios/`;
const WORK_IMAGE_ENDPOINT = `${settings.API_BASE_URL}catalog/works/images/`;

async function getWorkVideo(uid: string): Promise<WorkVideo> {
  const url = `${WORK_VIDEO_ENDPOINT}${uid}/`;
  const response = await APIClient.get(url);
  return response.data;
}

async function getWorkText(uid: string): Promise<WorkText> {
  const url = `${WORK_TEXT_ENDPOINT}${uid}/`;
  const response = await APIClient.get(url);
  return response.data;
}

async function getWorkAudio(uid: string): Promise<WorkAudio> {
  const url = `${WORK_AUDIO_ENDPOINT}${uid}/`;
  const response = await APIClient.get(url);
  return response.data;
}

async function getWorkImage(uid: string): Promise<WorkImage> {
  const url = `${WORK_IMAGE_ENDPOINT}${uid}/`;
  const response = await APIClient.get(url);
  return response.data;
}

export { getWorkVideo, getWorkText, getWorkAudio, getWorkImage };
