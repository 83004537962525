import "./style/main.scss";

import { createApp } from "vue";
import { createI18n } from "vue-i18n";
import { createPinia } from "pinia";
import * as Sentry from "@sentry/vue";
import { Integrations } from "@sentry/tracing";
import settings from "@/settings";

import App from "./App.vue";
import router from "./router";
import ObjRef from "@/components/ref/ObjRef.vue";
// @ts-ignore
import VueSocialSharing from "vue-social-sharing";
import Vue3TouchEvents from "vue3-touch-events";
import "@animxyz/core";
import VueAnimXyz from "@animxyz/vue3";
import VueLazyLoad from "vue3-lazyload";

const pinia = createPinia();

const app = createApp(App);
app.use(pinia);

// @ts-ignore
import de from "@/locales/de.yml";
// @ts-ignore
import en from "@/locales/en.yml";
// @ts-ignore
import fr from "@/locales/fr.yml";

// const en = {};
// const de = {};
// const fr = {};

const i18n = createI18n({
  legacy: false,
  // locale: settingsStore.locale,
  fallbackLocale: "de",
  messages: {
    de,
    en,
    fr,
  },
});

app.use(router);
app.use(i18n);
app.use(Vue3TouchEvents);
app.use(VueAnimXyz);
app.use(VueSocialSharing);
app.use(VueLazyLoad, {});

app.component("obj-ref", ObjRef);

Sentry.init({
  app,
  dsn: settings.SENTRY_DSN,
  integrations: [
    new Integrations.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: ["local.beyeler-collection", "collection.fondationbeyeler.ch", /^\//],
    }),
  ],
  tracesSampleRate: 1.0,
});

app.mount("#app");
