import { computed, onMounted, watch, ref, onActivated } from "vue";
import type { Ref } from "vue";
import { round } from "lodash-es";
import { unrefElement, useElementBounding, useWindowSize } from "@vueuse/core";
import { useUiStore } from "@/stores/ui";
import { useDeviceExtra } from "@/composables/device";
import { useDevice } from "@/composables/device";
import { TransitionPresets, useTransition } from "@vueuse/core";

const Y_FOCUS_START = 0.2;
const Y_FOCUS_CENTER = 0.5;
const Y_FOCUS_END = 0.8;

const useViewportFocus = (el?: Ref<HTMLElement | SVGElement | null>) => {
  // vp values
  const { height: vpHeight } = useWindowSize();
  const focusStart = computed(() => vpHeight.value * Y_FOCUS_START);
  const focusCenter = computed(() => vpHeight.value * Y_FOCUS_CENTER);
  const focusEnd = computed(() => vpHeight.value * Y_FOCUS_END);
  const focusRange = computed(() => focusEnd.value - focusStart.value);
  const { scrollY, isScrollWheel } = storeToRefs(useUiStore());
  const { scrollOffset } = useDeviceExtra();
  const { isSafari } = useDevice();

  //offset from center (mobile address bar)
  const relScrollY = computed(() => {
    return scrollY.value + scrollOffset.value / 2;
  });

  const elAbsY = ref(0);
  const elHeight = ref(0);

  const setElAbsY = () => {
    if (el?.value instanceof HTMLElement && el.value !== null ) {
      elAbsY.value = el?.value.offsetTop;
    }
  };

  const setElHeight = () => {
    if (el?.value instanceof HTMLElement && el.value !== null ) {
      elHeight.value = el.value.clientHeight;
    }
  };

  onMounted(() => {
    setElAbsY();
    setElHeight();
  });

  onActivated(() => {
    setElAbsY();
    setElHeight();
  });

  watch(() => scrollY.value, setElAbsY);

  watch(
    () => scrollY.value,
    () => {
      setElHeight();
      setElAbsY();
    }
  );

  const relY = computed(() => {
    if (!scrollY) {
      return 0;
    }
    return elAbsY.value - relScrollY.value;
  });

  const yCenter = computed(() => relY.value + elHeight.value / 2);

  const visible = computed(() => {
    return relY.value < vpHeight.value;
  });

  // focus calculations
  const inFocus = computed(() => {
    return yCenter.value > focusStart.value && yCenter.value < focusEnd.value;
  });

  const calcFocus = computed(() => {
    const centerDiff = focusCenter.value - yCenter.value;
    const rawFocus = 1 - Math.abs(centerDiff.valueOf()) / (focusRange.value / 2);
    return round(Math.max(rawFocus, 0), 3);
  });

  const calcSmoothFocus = useTransition(calcFocus, {
    duration: 150,
    transition: TransitionPresets.easeOutCubic,
  });

  const focusValue = computed(() => {
    if (isSafari && isScrollWheel.value) {
      return calcSmoothFocus.value;
    }
    return calcFocus.value;
  });

  const focusDirection = computed(() => {
    const centerDiff = focusCenter.value - yCenter.value;
    return centerDiff <= 0 ? "in" : "out";
  });

  return {
    visible,
    focusStart,
    focusCenter,
    focusEnd,
    inFocus,
    focusValue,
    calcSmoothFocus,
    focusDirection,
    elAbsY,
  };
};

const useScrollContainer = (el?: Ref<HTMLElement | null>) => {
  const { width, height } = useElementBounding(el);
  const { y: scrollY } = useScroll(el);
  const { setScrollY, setScrollBarWidth, scrollY: y, setScrollContainerHeight } = useUiStore();
  const scrollContainer = unrefElement(el);

  watch(() => scrollY.value, setScrollY);
  watch(() => height.value, setScrollContainerHeight);
  watch(
    () => width.value * height.value,
    () => {
      if (scrollContainer instanceof HTMLElement && scrollContainer !== null) {
        setScrollBarWidth(scrollContainer.offsetWidth - scrollContainer.clientWidth);
      }
    }
  );
  return {
    y,
  };
};

import { useScroll } from "@vueuse/core";
import { storeToRefs } from "pinia";

const useScrollExtra = (el?: Ref<HTMLElement | null>) => {
  const { directions, isScrolling } = useScroll(el);
  const uiStore = useUiStore();

  const scrollUp = computed(() => {
    return directions.top && isScrolling.value;
  });

  const scrollDown = computed(() => {
    return directions.bottom && isScrolling.value;
  });

  watch(
    () => scrollUp.value,
    (value) => {
      uiStore.setScrollUp(value);
    }
  );

  watch(
    () => scrollDown.value,
    (value) => {
      uiStore.setScrollDown(value);
    }
  );

  watch(
    () => isScrolling.value,
    (value) => {
      uiStore.setIsScrolling(value);
    }
  );

  return {
    scrollDown,
    scrollUp,
    isScrolling,
  };
};

export { useViewportFocus, useScrollContainer, useScrollExtra };
