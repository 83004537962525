<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { refAutoReset, useWindowSize } from "@vueuse/core";
import { useDevice } from "@/composables/device";
import MainMenu from "@/components/navigation/MainMenu.vue";
import LanguageChooser from "@/components/navigation/LanguageChooser.vue";
import WorkListModeSwitch from "@/components/catalog/work/WorkListModeSwitch.vue";
import Button from "@/components/ui/button/Button.vue";
import LogoFB from "@/assets/image/logo-fb.svg";
// @ts-ignore
import vClickOutside from "click-outside-vue3";
import WorkIndexLink from "@/components/catalog/work/WorkIndexLink.vue";
import { storeToRefs } from "pinia";
import { useUiStore } from "@/stores/ui";

export default defineComponent({
  props: {
    showModeSwitch: {
      type: Boolean,
      default: false,
    },
    showWorkIndexLink: {
      type: Boolean,
      default: false,
    },
  },
  directives: {
    clickOutside: vClickOutside.directive,
  },
  components: {
    WorkIndexLink,
    Button,
    MainMenu,
    LanguageChooser,
    WorkListModeSwitch,
  },
  setup() {
    const { t, locale: i18nLocale } = useI18n();
    const route = useRoute();
    const locale = computed(() => {
      return i18nLocale.value === "de" ? "" : i18nLocale.value;
    });
    const { scrollY, scrollLock, scrollDown, scrollBarWidth, modalOpen } = storeToRefs(
      useUiStore()
    );
    const { height: windowHeight } = useWindowSize();
    const isRouting = refAutoReset(false, 1000);
    const { isMobile } = useDevice();
    const showPanel = ref(false);
    const lockPanel = ref(false);

    const togglePanel = () => {
      if (!lockPanel.value) {
        showPanel.value = !showPanel.value;
      }
    };

    const closePanel = () => {
      showPanel.value = false;
    };

    const showNavigation = computed(() => {
      if (isMobile.value) {
        if (scrollY.value > windowHeight.value / 2 && !modalOpen.value) {
          return isRouting.value || !scrollDown.value || showPanel.value;
        }
        return !modalOpen.value;
      }
      if (scrollY.value > windowHeight.value / 2) {
        return isRouting.value || !scrollDown.value || showPanel.value;
      }
      return true;
    });

    const auxStyles = computed(() => {
      if (modalOpen.value && !isMobile.value && !showPanel.value) {
        return {
          transform: `translateX(calc(-40vw + 7.5rem + var(--button-size) + var(--grid-gap))`,
        };
      }
      return {
        transform: `translateX(-${scrollBarWidth.value}px)`,
      };
    });

    const onClickOutside = () => {
      if (!isMobile.value) {
        closePanel();
      }
    };

    watch(
      () => route.path,
      () => {
        isRouting.value = true;
        closePanel();
      }
    );

    watch(
      () => showPanel.value,
      (value) => {
        scrollLock.value = value;
      }
    );

    return {
      t,
      locale,

      //panel
      togglePanel,
      lockPanel,
      closePanel,
      showPanel,
      onClickOutside,

      //imprint
      LogoFB,

      //navigation display
      showNavigation,
      auxStyles,
    };
  },
});
</script>

<template>
  <transition name="slide-top">
    <nav v-if="showNavigation" class="navigation">
      <div class="navigation--toggle">
        <transition name="slide-left">
          <Button
            v-touch:tap="togglePanel"
            :label="t('menu.menu')"
            :class="{ 'is-active': showPanel }"
          />
        </transition>
      </div>
      <div class="navigation--aux" :style="auxStyles">
        <transition name="slide-right">
          <WorkListModeSwitch
            class="mode-switch"
            v-if="showModeSwitch && !showPanel"
            @navigate="closePanel"
          />
        </transition>
        <transition name="slide-right">
          <WorkIndexLink v-if="showWorkIndexLink && !showPanel" class="index-link" />
        </transition>
        <transition name="slide-right">
          <LanguageChooser class="language-switch" v-if="showPanel" />
        </transition>
      </div>

      <transition
        name="slide"
        @enter="lockPanel = true"
        @after-enter="lockPanel = false"
        @leave="lockPanel = true"
        @after-leave="lockPanel = false"
        :duration="600"
      >
        <div v-if="showPanel" class="panel">
          <MainMenu class="menu menu--main" @close="closePanel" v-click-outside="onClickOutside" />
          <div class="transition-staggered imprint">
            <a class="brand" :href="`${t('link.cfb-site')}`">
              <img class="logo" :src="LogoFB" alt="Fondation Beyeler" />
            </a>
            <ul class="links list--undecorated">
              <li>
                <router-link
                  :to="{
                    name: 'imprint',
                    params: {
                      locale,
                    },
                  }"
                >
                  <span v-text="`${t('menu.imprint')}`" />
                </router-link>
              </li>
              <li><span class="separator">|</span></li>
              <li>
                <a target="_blank" :href="`${t('link.cfb-privacy')}`">
                  <span v-text="`${t('menu.privacy')}`" />
                </a>
              </li>
            </ul>
          </div>
        </div>
      </transition>
      <transition name="scrim">
        <div v-if="showPanel" class="scrim" />
      </transition>
    </nav>
  </transition>
</template>

<style lang="scss" scoped>
@use "@/style/abstracts/responsive";
@use "@/style/base/typo";
@use "@/style/base/transition";
@use "@/style/elements/link";

.navigation {
  position: fixed;
  top: 0;
  z-index: calc(var(--nav-z-index) + 1);
  width: 100%;
  opacity: 1;

  .navigation--toggle,
  .navigation--aux {
    position: fixed;
    top: 0;
    z-index: calc(var(--nav-z-index) + 3);
    margin: var(--grid-gap);
  }

  .navigation--toggle {
    left: 0;
  }

  .navigation--aux {
    right: 0;
    display: flex;
    flex-direction: row;
    transition: transform 350ms ease-out;

    .language-switch {
      position: absolute;
      right: 0;
    }
  }

  .panel {
    width: 100%;
    height: calc(var(--vh) * 100);
    position: fixed;
    z-index: calc(var(--nav-z-index) + 2);
    padding: var(--ui-content-top) var(--grid-gap) var(--grid-gap) var(--grid-gap);
    overflow-y: scroll;
    display: flex;
    flex-direction: column;
    overflow: hidden;

    > * {
      color: rgb(var(--c-fg));
    }
    @include responsive.bp-large-up {
      padding: var(--grid-gap);
      width: 100%;

      > *:not(:last-child) {
        width: 60vw;
        margin-left: auto;
        margin-right: auto;
      }
      > * {
        pointer-events: auto;
      }
    }
  }

  .imprint {
    @include typo.small;

    display: flex;
    flex-direction: column;
    width: 100%;
    margin: auto auto 0 auto;

    @include responsive.bp-large-up {
      width: 100%;
      max-width: 100%;
      position: relative;
      display: flex;
      justify-content: flex-end;
      align-items: center;
    }

    .brand {
      padding: var(--grid-gap) 0;
      max-width: 220px;
      margin: auto;

      @include responsive.bp-large-up {
        padding: var(--grid-gap) 0 0;
        max-width: 256px;
      }

      .logo {
        width: 100%;
      }
    }

    .links {
      display: flex;
      flex-direction: row;
      //justify-content: space-between;
      grid-column-gap: var(--grid-gap);
      margin: auto;

      @include responsive.bp-large-up {
        position: absolute;
        right: 0;
      }

      a,
      span {
        @include link.secondary;

        &.separator {
          pointer-events: none;
        }
      }
    }
  }
}

/* slide transitions */
.slide-enter-active,
.slide-leave-active {
  transition-property: transform, opacity;
  transition-duration: 0.5s;
  transition-timing-function: ease-in-out;

  @include responsive.bp-large-up {
    pointer-events: auto !important;
  }

  .transition-staggered {
    transition-property: transform, opacity;
    transition-duration: 0.2s;
    transition-timing-function: ease-in-out;
  }
}

.slide-enter-active {
  .transition-staggered {
    transition-delay: 0.25s;
  }
}

.slide-leave-active {
  transition-delay: 0.1s;
}

.slide-enter-from,
.slide-leave-to {
  transform: translateY(-100vh);
  opacity: 0.5;
  .transition-staggered {
    opacity: 0;
    transform: translateY(200px);
  }
}

.slide-enter-to {
  transform: translateY(0vh);
  opacity: 1;
  .transition-staggered {
    opacity: 1;
    transform: translateY(0);
  }
}

/* slide-right transitions */
.slide-right-enter-active,
.slide-right-leave-active {
  transition-property: opacity, transform;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
}
.slide-right-enter-from,
.slide-right-leave-to {
  transform: translateX(200px);
  opacity: 0.8;
}
.slide-right-enter-to {
  transform: translateX(0);
  opacity: 1;
}

/* slide-left transitions */
.slide-left-enter-active,
.slide-left-leave-active {
  transition-property: opacity, transform;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
}
.slide-left-enter-from,
.slide-left-leave-to {
  transform: translateX(-200px);
  opacity: 0.8;
}
.slide-left-enter-to {
  transform: translateX(0);
  opacity: 1;
}

/* slide-top transitions */
.slide-top-enter-active,
.slide-top-leave-active {
  transition-delay: 100ms;
  transition-property: opacity, transform;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
}
.slide-top-enter-from,
.slide-top-leave-to {
  transform: translateY(-100px);
  opacity: 0;
}
.slide-top-enter-to {
  transform: translateX(0);
  opacity: 1;
}

/* pull-right transitions */
.pull-right-enter-active,
.pull-right-leave-active {
  transition-property: opacity, margin-right;
  transition-duration: 200ms;
  transition-timing-function: ease-in-out;
}
.pull-right-enter-from,
.pull-right-leave-to {
  margin-right: calc(-1 * (var(--button-size) + var(--grid-gap)));
  opacity: 0.8;
}
.pull-right-enter-to {
  margin-right: 0;
  opacity: 1;
}
</style>
