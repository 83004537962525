<script lang="ts">
import { computed, defineComponent, onActivated, watch, ref } from "vue";
import { unrefElement, useScrollLock } from "@vueuse/core";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { useScrollContainer } from "@/composables/ui";
import Splash from "@/components/introduction/Splash.vue";
import eventBus from "@/eventBus";
import { useUiStore } from "@/stores/ui";
import { useScrollExtra } from "@/composables/ui";
import { storeToRefs } from "pinia";
import { useDevice } from "@/composables/device";

export default defineComponent({
  components: { Splash },
  props: {
    locale: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const { locale: i18nLocale } = useI18n({ useScope: "global" });
    const route = useRoute();
    const scrollContainer = ref<HTMLElement | null>(null);
    const { scrollLock, isScrolling, isScrollWheel } = storeToRefs(useUiStore());
    const { setScrollWheel } = useUiStore();
    const isLocked = useScrollLock(scrollContainer);
    const { isMobile, isSafari } = useDevice();

    useScrollExtra(scrollContainer);
    useScrollContainer(scrollContainer);

    eventBus.on("base:scrollTo", (pos: any) => {
      unrefElement(scrollContainer)?.scrollTo({
        top: pos,
        //@ts-ignore
        behavior: "instant",
      });
    });

    watch(
      () => scrollLock.value,
      (value) => {
        if (!isMobile.value) {
          isLocked.value = value;
        }
      }
    );

    const transitionName = computed(() => {
      const tr = route.params.transition;
      // NOTE: just for the moment...
      return tr ? `${tr}` : tr;
    });

    const swapScrollSnapStyle = computed(() => {
      if (isSafari && isScrolling.value && isScrollWheel.value) {
        return {
          scrollSnapType: "none",
        };
      }
      return "";
    });

    const detectScrollWheel = (e: any) => {
      setScrollWheel(e.deltaY % 1 !== 0);
    };

    onActivated(() => {
      i18nLocale.value = props.locale;
    });

    watch(
      () => props.locale,
      (newValue) => {
        i18nLocale.value = newValue;
      }
    );

    return {
      scrollContainer,
      transitionName,
      detectScrollWheel,
      swapScrollSnapStyle,
    };
  },
});
</script>

<template>
  <Splash />
  <div class="base">
    <router-view name="tutorial" />
    <router-view name="navigation" />
    <router-view v-slot="{ Component, route }">
      <transition :name="transitionName || route.meta.transition">
        <keep-alive>
          <component
            class="scroll-container"
            @wheel="detectScrollWheel"
            ref="scrollContainer"
            :is="Component"
            :key="route.params.uid"
            :style="swapScrollSnapStyle"
          />
        </keep-alive>
      </transition>
    </router-view>
  </div>
</template>

<style lang="scss" scoped>
@use "@/style/abstracts/responsive";
@use "@/style/base/scroll";

.base {
  position: relative;
  min-height: 100vh;

  .scroll-container {
    @include scroll.snap-container;
  }
}

.slide-right-enter-active,
.slide-right-leave-active,
.slide-left-enter-active,
.slide-left-leave-active,
.slide-right-enter-to,
.slide-left-enter-to,
.slide-right-leave-to,
.slide-left-leave-to {
  &.artwork-first {
    position: fixed;
    top: 0;
    z-index: 2;
    height: 100vh;
  }
}

.slide-left-enter-active,
.slide-left-leave-active {
  position: absolute;
  width: 100vw;
  top: 0;
  height: 100%;
  min-height: calc(100 * var(--vh));
  z-index: 1;
  transition: transform 350ms ease-in-out, opacity 250ms ease-out;
  -webkit-perspective: initial;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
}
.slide-left-enter-from {
  transform: translateX(100vw);
  opacity: 0;
}
.slide-left-leave-to {
  transform: translateX(-100vw);
  opacity: 0;
}

.slide-right-enter-active,
.slide-right-leave-active {
  position: absolute;
  width: 100vw;
  top: 0;
  height: 100%;
  min-height: calc(100 * var(--vh));
  z-index: 1;
  transition: transform 350ms ease-in-out, opacity 250ms ease-out;
  -webkit-perspective: initial;
  -webkit-backface-visibility: hidden;
  -webkit-transform: translateZ(0);
}
.slide-right-enter-from {
  transform: translateX(-100vw);
  opacity: 0;
}
.slide-right-leave-to {
  transform: translateX(100vw);
  opacity: 0;
}

//reveal transition (WorkDetail)
.reveal-enter-active,
.reveal-leave-active {
  width: 100%;
  transition-timing-function: ease-in-out;
  transition-duration: 500ms;
  transition-property: opacity, transform;

  :deep(.text-work) {
    transition-timing-function: ease-in-out;
    transition-duration: 500ms;
    transition-property: transform, opacity;
  }
}

.reveal-enter-from,
.reveal-leave-to {
  opacity: 0;
  transform: scale(1);

  :deep(.text-work) {
    &.in-focus {
      transform: scale(1.1);
      opacity: 0;
    }
  }
}
.reveal-enter-to {
  opacity: 1;
  transform: scale(1);
  :deep(.text-work) {
    &.in-focus {
      transform: scale(1);
    }
  }
}
</style>
