<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import type { Product } from "@/typings/api";
import showdown from "showdown";
import { getProduct } from "@/api/foundation";
import { useI18n } from "vue-i18n";
import Button from "@/components/ui/button/Button.vue";
import settings from "@/settings";

const converter = new showdown.Converter();
export default defineComponent({
  components: {
    Button,
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const obj = ref<Product | null>(null);
    const endpoint = settings.IMAGE_RESIZER_ENDPOINT;

    const loadObj = async () => {
      obj.value = await getProduct(props.uid);
    };

    watch(() => props.uid, loadObj);

    onMounted(loadObj);

    const body = computed(() => {
      const raw = obj.value?.body ?? "";
      return converter.makeHtml(raw);
    });

    const ratio = computed(() => {
      return obj.value?.image?.ratio ?? 1;
    });

    const path = computed(() => {
      return obj.value?.image?.path ?? "";
    });

    const src = computed(() => {
      // eg: /images/crop/80x80/catalog/work/AB7DD2F0/6308E497.jpg
      return `${endpoint}crop/${800}x${800 / ratio.value}/${path.value}`;
    });

    return {
      obj,
      t,
      body,
      src,
      ratio,
    };
  },
});
</script>

<template>
  <article class="product">
    <div class="product-visual">
      <transition name="fade" appear>
        <img v-if="src" :alt="obj?.title" :src="src" :style="{ aspectRatio: `1 / ${ratio}` }" />
      </transition>
    </div>
    <h3 v-if="obj?.type" class="type" v-text="obj?.type" />
    <h1 v-if="obj?.title" class="title" v-text="obj?.title" />
    <p v-if="obj?.teaser" class="teaser" v-text="obj?.teaser" />
    <div v-if="body" class="body" v-html="body" />
    <a v-if="obj?.shopUrl" :href="obj?.shopUrl" target="_blank">
      <Button :label="`${t('rich-media.shop')}`" class="invert" />
    </a>
  </article>
</template>

<style lang="scss" scoped>
@use "@/style/base/typo";
@use "@/style/abstracts/responsive";

.product {
  .product-visual {
    display: flex;
    justify-content: center;
    margin-bottom: calc(2 * var(--grid-gap));

    img {
      object-fit: contain;
      max-width: 200px;
      min-width: 75%;
      max-height: 100%;
      height: auto;
      width: 100%;

      @include responsive.bp-large-up {
        max-width: 50%;
        min-width: 214px;
      }
    }
  }

  > *:not(.visual) {
    @include typo.content-alt-aux;
    &:not(:last-child) {
      &:after {
        display: block;
        content: "\a";
        white-space: pre;
      }
    }
  }
}

/* fade transitions */
.fade-enter-active,
.fade-leave-active {
  transition-delay: 200ms;
  transition-property: opacity;
  transition-duration: 500ms;
  transition-timing-function: ease-in-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}

.fade-enter-to {
  opacity: 1;
}
</style>
