import type { Work, Artist } from "@/typings/api";
import { APIClient } from "@/api/client";
import settings from "@/settings";

const WORK_ENDPOINT = `${settings.API_BASE_URL}catalog/works/`;
const ARTIST_ENDPOINT = `${settings.API_BASE_URL}catalog/artists/`;

async function getWorks(limit: number): Promise<Work[] | []> {
  let url = WORK_ENDPOINT;
  const params = {
    limit,
    expand: [],
  };
  let works: Work[] = [];
  while (url) {
    const response = await APIClient.get(url, { params });
    const { results, next } = response.data;
    url = next || "";
    works = [...works, ...results];
  }
  return works;
}

async function getWork(uid: string): Promise<Work> {
  const url = `${WORK_ENDPOINT}${uid}/`;
  const params = {
    expand: [],
  };
  const response = await APIClient.get(url, { params });
  return response.data;
}

async function getArtist(uid: string): Promise<Artist> {
  const url = `${ARTIST_ENDPOINT}${uid}/`;
  const params = {
    expand: [],
  };
  const response = await APIClient.get(url, { params });
  return response.data;
}

export { getWorks, getWork, getArtist };
