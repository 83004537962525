<script lang="ts">
import { defineComponent, computed, ref, watch, onMounted } from "vue";
import YouTube from "vue3-youtube";
import type { WorkVideo } from "@/typings/api";
import { getWorkVideo } from "@/api/catalogRichMedia";
import eventBus from "@/eventBus";

export default defineComponent({
  components: {
    YouTube,
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const playerEl = ref<HTMLElement | null>(null);
    const obj = ref<WorkVideo | null>(null);
    const src = computed(() => obj.value?.url ?? "");
    const caption = computed(() => obj.value?.caption ?? "");

    // https://developers.google.com/youtube/player_parameters#Parameters
    const playerOpts = ref({
      autoplay: 0,
      modestbranding: 1,
    });
    const onStateChange = (state: any) => {
      if (Number(state.data) === 1) {
        eventBus.emit("videoPlayer:startsPlaying");
      }
    };
    const loadObj = async () => {
      obj.value = null;
      obj.value = await getWorkVideo(props.uid);
    };
    watch(() => props.uid, loadObj);
    onMounted(loadObj);

    const pause = () => {
      // @ts-ignore
      playerEl.value?.pauseVideo();
    };

    // stop if primary (audio) player starts
    eventBus.on("audioPlayer:startsPlaying", pause);

    return {
      playerEl,
      src,
      caption,
      playerOpts,
      onStateChange,
    };
  },
});
</script>

<template>
  <div class="work-video">
    <YouTube
      ref="playerEl"
      v-if="src"
      class="video"
      :src="src"
      :vars="playerOpts"
      @state-change="onStateChange"
      width="100%"
      height="auto"
    />
    <div v-if="caption" class="credits" v-text="caption" />
  </div>
</template>

<style lang="scss" scoped>
.work-video {
  width: 100%;
  .video {
    :deep(iframe) {
      aspect-ratio: 16 / 9;
      height: 100%;
      width: 100%;
    }
  }
}
</style>
