<script lang="ts">
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";

export default defineComponent({
  setup() {
    const { locale, availableLocales } = useI18n({ useScope: "global" });
    const router = useRouter();
    const getLocalizedRoute = (code: string) => {
      return router.resolve({ params: { locale: code === "de" ? "" : code } });
    };
    const locales = computed(() => {
      return availableLocales.map((code: string) => {
        return {
          isCurrent: code === locale.value,
          code,
          to: getLocalizedRoute(code),
        };
      });
    });
    return {
      locales,
    };
  },
});
</script>

<template>
  <div class="language-chooser">
    <a
      v-for="(locale, index) in locales"
      :key="`locale-${index}-${locale.code}`"
      :href="locale.to.path"
      class="language"
      :class="{ 'is-current': locale.isCurrent }"
    >
      <span v-text="locale.code" />
    </a>
  </div>
</template>

<style lang="scss" scoped>
@use "@/style/base/typo";
@use "@/style/elements/button";

.language-chooser {
  display: flex;
  //grid-gap: calc(var(--grid-gap) / 2);

  .language {
    @include button.default;

    &:hover,
    &.is-current {
      @include button.active;
    }
  }
}
</style>
