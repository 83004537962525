<script lang="ts">
import { defineComponent, ref, watch, onMounted, computed } from "vue";
import type { WorkAudio } from "@/typings/api";
import { getWorkAudio } from "@/api/catalogRichMedia";
import InlineAudioPlayer from "@/components/audio-player/InlineAudioPlayer.vue";

export default defineComponent({
  components: {
    InlineAudioPlayer,
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const obj = ref<WorkAudio | null>(null);
    const loadObj = async () => {
      obj.value = await getWorkAudio(props.uid);
    };
    watch(() => props.uid, loadObj);
    onMounted(loadObj);
    const src = computed(() => {
      if (obj.value?.path) {
        return `/media/${obj.value.path}`;
      }
      return null;
    });
    return {
      obj,
      src,
    };
  },
});
</script>

<template>
  <div class="work-audio">
    <InlineAudioPlayer v-if="obj" :audio="obj" />
  </div>
</template>

<style lang="scss" scoped>
@use "@/style/base/typo";

.work-audio {
  min-height: 50px;

  :deep(.audio-player) {
    @include typo.base;

    width: 100%;
  }
}
</style>
