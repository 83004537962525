cfb:
  address:
    name: Fondation Beyeler
    department-digital: Digital Communication
    street: Baselstrasse 77
    po: "4125"
    city: Riehen/ Bâle
    country: Suisse
link:
  cfb-privacy: https://www.fondationbeyeler.ch/fr/politique-de-confidentialite-des-donnees
  cfb-site: https://www.fondationbeyeler.ch/fr
  cfb-facebook: https://www.facebook.com/FondationBeyeler/
  cfb-youtube: https://www.youtube.com/channel/UCVKuxYFkse6gaNn0ri2Il0w
  cfb-instagram: https://www.instagram.com/fondationbeyeler/
  cfb-twitter: https://twitter.com/fond_beyeler
  cfb-newsletter: https://www.fondationbeyeler.ch/medien/newsletter
menu:
  home: Accueil
  collection: La Collection
  menu: Menu
  information: À propos
  catalog: Liste des œuvres
  imprint: Mentions légales
  privacy: Politique de confidentialité des données
  to-museum: Vers le site du musée
navigate:
  previous: Précédent
  next: Suivant
work-switch:
  text: Texte
  image: Image
player:
  play: Play
  stop: Stop
  pause: Pause
  resume: Play
work:
  title-credits: Crédits
  title-share: Share
  button-share: Partager la page
  current-exhibition: Voir l'exposition actuelle
  view-on-site: Voir le catalogue de la collection
rich-media:
  shop: Vers l'Art Shop
tutorial:
  story: De quelle œuvre s'agit-il ?
  audio: Découvrez les œuvres phares de la Collection Beyeler. Cliquez sur le texte ou écoutez l'audio pour commencer.
inline-content-about:
  collection-title: À propos de la Collection Beyeler
  collection: La Collection Beyeler constitue le véritable noyau de la Fondation Beyeler et son point de départ en termes de contenu. Pendant plus de 50 ans, le couple de collectionneurs et de galeristes Hildy et Ernst Beyeler a réuni des œuvres d'art moderne sélectionnées pour leur qualité exceptionnelle. Afin de rendre leur collection accessible au public, ils ont créé une fondation. En 1997, ils ont ouvert la Fondation Beyeler à leur domicile de Riehen, près de Bâle, dans un bâtiment du musée spécialement conçu par Renzo Piano. Dans ce lieu, l'art, la nature et l'architecture se combinent pour créer une expérience sensorielle unique. Depuis le décès du couple de fondateurs, la collection se concentre sur l'art contemporain. L'accent est mis de plus en plus sur les œuvres d'artistes féminines, qui étaient jusqu'à présent sous-représentées dans la collection.
  collection-online-title: La collection Beyeler en ligne
  collection-online: À l'occasion du 25e anniversaire de la Fondation Beyeler, une sélection d'œuvres majeures de la collection est rendue accessible et peut être découverte en ligne. Sous le titre « Beyeler Collection Stories » se trouvent les histoires des différentes œuvres et des artistes, qui sont ici rassemblées. Enrichis de médias interactifs et d'informations de fond sur les 25 années d'existence de la Fondation Beyeler, les contenus offrent un premier aperçu ludique de la collection et invitent en même temps à une étude approfondie de celle-ci. Les « Beyeler Collection Stories » sont encore en cours de réalisation. Elles complètent le « Catalogue en ligne de la collection » de la Fondation Beyeler.
  social-title: Restez au courant
page:
  imprint:
    title: Mentions légales
    subtitle-content: Responsables des contenue
    subtitle-design: Design
    subtitle-code: Réalisation technique
    subtitle-photography: Photographie
    content-photography: Robert Bayer, Peter Schibli, Kurt Wyss, et autres
    subtitle-text: Production de textes
    subtitle-editing: Lectrotat er relecture
    notes: Malgré tous nos efforts, il ne nous a pas été possible d’identifier l’ensemble des auteurs et l'origine de toutes les illustrations de ce site. Toute revendication éventuelle fera l’objet d’une analyse rigoureuse dans le respect de la réglementation applicable, sur présentation des pièces justificatives adéquates.
  notfound:
    title: Page non trouvée
    home: Retour à la page d'accueil
