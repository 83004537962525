<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import IconCaret from "@/components/ui/icon/IconCaret.vue";
import RichMedia from "@/components/catalog/work/detail/rich-media/RichMedia.vue";
import Button from "@/components/ui/button/Button.vue";
import { useDevice } from "@/composables/device";
import eventBus from "@/eventBus";
import { useEventListener } from "@vueuse/core";
import { useUiStore } from "@/stores/ui";
import { storeToRefs } from "pinia";

export default defineComponent({
  components: {
    Button,
    IconCaret,
    RichMedia,
  },
  props: {
    title: {
      type: String,
      default: "",
    },
    lookup: {
      type: String,
      default: "",
    },
    visible: {
      type: Boolean,
      default: false,
    },
  },
  setup(props) {
    const el = ref<HTMLElement | null>(null);
    const { isMobile } = useDevice();
    const { modalOpen } = storeToRefs(useUiStore());
    const { scrollBarWidth } = storeToRefs(useUiStore());
    const displayPanel = computed(() => {
      return props.lookup && props.visible;
    });

    //try catching browser swipe back navigation & prevent browser back (use swipe to close modal)
    useEventListener(el, "touchstart", (e: TouchEvent) => {
      //@ts-ignore (TouchEvent exists with pageX)
      if (e.pageX > 20 && e.pageX < window.innerWidth - 20) return;
      e.preventDefault();
    });

    watch(
      () => displayPanel.value,
      (value) => {
        modalOpen.value = !!value;
      }
    );

    const close = () => {
      eventBus.emit("objRef:hide");
    };

    const onSwipeRight = () => {
      if (isMobile.value) {
        close();
      }
    };

    const transition = computed(() => {
      if (isMobile.value) {
        return "slide-up";
      }
      return "slide-left";
    });

    const transitionDirection = computed(() => {
      if (isMobile.value) {
        return "down";
      }
      return "right";
    });

    return {
      el,
      transition,
      transitionDirection,
      isMobile,
      close,
      onSwipeRight,
      displayPanel,
      scrollBarWidth,
    };
  },
});
</script>
<template>
  <Teleport to="#objRefTarget">
    <transition :name="transition" @after-leave="close">
      <aside
        ref="el"
        v-if="displayPanel"
        class="rich-media-panel"
        v-touch:swipe.right="onSwipeRight"
        :style="{
          paddingRight: `${scrollBarWidth}px`,
        }"
      >
        <header class="header">
          <div class="title">
            <span v-text="title" />
          </div>
          <div class="close">
            <Button v-touch:tap="close" class="primary is-icon">
              <IconCaret :size="48" :direction="transitionDirection" />
            </Button>
          </div>
        </header>
        <section class="body">
          <RichMedia :lookup="lookup" />
        </section>
        <div v-if="visible && isMobile" class="scrim" />
      </aside>
    </transition>
  </Teleport>
</template>

<style lang="scss" scoped>
@use "@/style/abstracts/responsive";
@use "@/style/base/typo";
@use "@/style/base/transition";
@use "@/style/elements/button";

.rich-media-panel {
  --rich-media-panel-width: 100vw;

  @include responsive.bp-large-up {
    --rich-media-panel-width: calc(40vw - (4 * var(--grid-gap)) - 7.5rem);
  }

  width: var(--rich-media-panel-width);
  margin: 0;
  position: fixed;
  overflow-y: auto;
  height: calc(100 * var(--vh));
  top: 0;
  left: 0;
  z-index: var(--rich-media-z-index);
  background: transparent;
  display: flex;
  flex-direction: column;

  @include responsive.bp-large-up {
    right: 0;
    left: initial;
    height: calc(100vh - var(--audio-player-height));
  }

  .header {
    position: sticky;
    top: 0;
    display: flex;
    flex-direction: row;
    align-items: center;
    padding: var(--grid-gap) var(--grid-gap) calc(2 * var(--grid-gap));
    z-index: calc(var(--rich-media-z-index) + 1);

    .title {
      flex-grow: 1;
      display: flex;
      justify-content: center;

      span {
        @include button.primary;
        @include typo.nav-aux;
        width: fit-content;
        cursor: inherit;

        &:not(:disabled) {
          @include responsive.on-hover {
            background: rgb(var(--c-primary));
            border: 1px solid rgb(var(--c-primary));
          }
        }
      }
    }

    .close {
      width: fit-content;
    }
  }
  .body {
    flex-grow: 1;
    padding: 0 var(--grid-gap) calc(2 * var(--grid-gap));
    z-index: var(--rich-media-z-index);
  }
}

/* slide-up transitions */
.slide-up-enter-active,
.slide-up-leave-active {
  transition-delay: 100ms;
  transition-property: transform;
  transition-duration: 350ms;
  transition-timing-function: ease-in-out;
}

.slide-up-leave-to,
.slide-up-enter-from {
  transform: translateY(100vh);
}

.slide-up-enter-to {
  transform: translateX(0);
}

/* slide-left transitions */
.slide-left-enter-active,
.slide-left-leave-active {
  transition-delay: 0ms;
  transition-property: opacity, transform;
  transition-duration: 350ms;
  transition-timing-function: ease-out;
}
.slide-left-enter-from,
.slide-left-leave-to {
  transform: translatex(calc(40vw - 4 * var(--grid-gap)));
  opacity: 0;
}
.slide-left-enter-to {
  transform: translateX(0);
  opacity: 1;
}
</style>
