<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { useElementSize } from "@vueuse/core";
import Button from "@/components/ui/button/Button.vue";

export default defineComponent({
  components: { Button },
  inheritAttrs: false,
  props: {
    choices: {
      type: Object,
      required: true,
    },
    activeChoice: {
      type: Number,
      default: 0,
    },
    transitionDuration: {
      type: Number,
      default: 250,
    },
  },
  emits: ["choice"],
  setup(props, { emit }) {
    const el = ref<HTMLElement | null>(null);
    const { width } = useElementSize(el);
    const active = ref(props.activeChoice | 0);
    

    const style = computed(() => {
      
      let activeWidth = 0;
      let activeOffset = 0;
      if (el.value) {
        if (el.value.children[active.value] instanceof HTMLElement && el.value.children[active.value] !== null && width.value) {
          //@ts-ignore
          activeWidth = el.value.children[active.value].offsetWidth;
          //@ts-ignore
          activeOffset = el?.value.children[active.value].offsetLeft;
        }
      }

      return {
        "--active-width": `${activeWidth}px`,
        "--active-offset": `${activeOffset}px`,
        "--active-duration": `${props.transitionDuration}ms`,
      };
    });

    const setChoice = (index: number, choice: any) => {
      return function () {
        if (index !== active.value) {
          active.value = index;
          emit("choice", choice);
        }
      };
    };

    return {
      el,
      style,
      setChoice,
    };
  },
});
</script>

<template>
  <div ref="el" class="choices-button" :style="style">
    <Button
      v-for="(choice, key, index) in choices"
      class="choice"
      :key="`choice-${key}`"
      :label="`${choice.label}`"
      v-touch:tap="setChoice(index, choice)"
    />
    <span class="selector"></span>
  </div>
</template>

<style lang="scss" scoped>
@use "@/style/abstracts/responsive";
@use "@/style/elements/button";

.choices-button {
  --active-width: 0;
  --active-offset: -1px;
  --active-duration: 350ms;

  display: flex;
  flex-direction: row;
  position: relative;

  button {
    border-radius: 0;
    &:first-of-type {
      border-top-left-radius: var(--border-radius);
      border-bottom-left-radius: var(--border-radius);
    }
    &:last-of-type {
      border-top-right-radius: var(--border-radius);
      border-bottom-right-radius: var(--border-radius);
    }
    &:focus {
      border: 1px solid transparent;
    }
    &:not(:disabled) {
      @include responsive.on-hover {
        border: 1px solid transparent;
      }
    }
  }

  .selector {
    @include button.default;
    pointer-events: none;
    background-color: transparent;
    height: calc(100% - 2px); //twice border size
    width: var(--active-width);
    border-color: rgb(var(--c-interface-fg));
    transform: translateX(var(--active-offset));
    position: absolute;
    transition-property: width, transform;
    transition-duration: var(--active-duration);
    transition-timing-function: ease-in-out;
  }
}
</style>
