<script lang="ts">
import { computed, defineComponent } from "vue";
import { useI18n } from "vue-i18n";
import { useRouter } from "vue-router";
import ChoicesButton from "@/components/ui/button/ChoicesButton.vue";
import { storeToRefs } from "pinia";
import { useCatalogStore } from "@/stores/catalog";

export default defineComponent({
  components: {
    ChoicesButton,
  },
  setup() {
    const { t, locale: i18nLocale } = useI18n();
    const { listMode } = storeToRefs(useCatalogStore());
    const router = useRouter();
    const locale = computed(() => {
      return i18nLocale.value === "de" ? "" : i18nLocale.value;
    });

    const choices = {
      storyFirst: {
        label: t("work-switch.text"),
        route: "storyFirst",
        transition: "slide-right",
      },
      artworkFirst: {
        label: t("work-switch.image"),
        route: "artworkFirst",
        transition: "slide-left",
      },
    };

    const navigate = (choice: any) => {
      listMode.value = choice.route;
      router.push({
        name: choice.route,
        params: {
          locale: locale.value,
          transition: choice.transition,
        },
      });
    };

    const activeChoice = computed(() => {
      return Object.keys(choices).indexOf(listMode.value);
    });

    return {
      t,
      navigate,
      choices,
      activeChoice,
    };
  },
});
</script>

<template>
  <div class="work-layout-switch">
    <ChoicesButton @choice="navigate" :active-choice="activeChoice" :choices="choices" />
  </div>
</template>

<style lang="scss" scoped>
.work-layout-switch {
  display: flex;
  flex-direction: row;

  .swap-display {
    padding: 0 0 0 var(--grid-gap);
  }
}
</style>
