import type { Product } from "@/typings/api";
import { APIClient } from "@/api/client";
import settings from "@/settings";

const PRODUCT_ENDPOINT = `${settings.API_BASE_URL}foundation/products/`;

async function getProduct(uid: string): Promise<Product> {
  const url = `${PRODUCT_ENDPOINT}${uid}/`;
  const response = await APIClient.get(url);
  return response.data;
}

export { getProduct };
