<script lang="ts">
import { defineComponent, toRef } from "vue";
import { TransitionPresets, useTransition } from "@vueuse/core";
import Duration from "./Duration.vue";

export default defineComponent({
  components: {
    Duration,
  },
  props: {
    seconds: {
      type: Number,
      default: 0,
    },
    tween: {
      type: Number,
      default: 500,
    },
    prefix: {
      type: String,
      default: "",
    },
  },
  setup(props) {
    const animatedValue = useTransition(toRef(props, "seconds"), {
      duration: props.tween,
      transition: TransitionPresets.easeInOutCubic,
    });
    return {
      animatedValue,
    };
  },
});
</script>
<template>
  <Duration :seconds="animatedValue" :prefix="prefix" />
</template>

<style lang="scss" scoped>
.animated-duration {
  display: inline-flex;
  white-space: nowrap;
}
</style>
