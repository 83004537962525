<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import MainMenuPanel from "@/components/navigation/MainMenuPanel.vue";
import ArtistIndex from "@/components/catalog/artist/index/Index.vue";
import Button from "@/components/ui/button/Button.vue";
import { useRoute, useRouter } from "vue-router";
import InlineContentAbout from "@/components/inline-content/InlineContentAbout.vue";

export default defineComponent({
  components: {
    InlineContentAbout,
    Button,
    MainMenuPanel,
    ArtistIndex,
  },
  emits: ["close"],
  setup(props, { emit }) {
    const { t, locale } = useI18n();
    const router = useRouter();
    const route = useRoute();
    const activePanelId = ref("");

    const currentRoute = computed(() => {
      return route.name;
    });

    const navigateStoryFirst = () => {
      if (currentRoute.value == "storyFirst") {
        emit("close");
      } else {
        router.push({
          name: "storyFirst",
          params: {
            locale: locale.value,
          },
        });
      }
    };

    const onOpenPanel = (value: string) => {
      activePanelId.value = value;
    };

    return {
      t,
      locale,
      currentRoute,
      navigateStoryFirst,
      onOpenPanel,
      activePanelId,
    };
  },
});
</script>

<template>
  <div class="main-menu">
    <div class="menu-item">
      <Button class="menu-item" v-touch="navigateStoryFirst" :label="t('menu.home')" />
    </div>
    <MainMenuPanel
      @open="onOpenPanel"
      class="menu-item"
      :label="t('menu.catalog')"
      :active-panel-id="activePanelId"
    >
      <ArtistIndex />
    </MainMenuPanel>
    <MainMenuPanel
      @open="onOpenPanel"
      class="menu-item"
      :label="t('menu.information')"
      :active-panel-id="activePanelId"
    >
      <InlineContentAbout />
    </MainMenuPanel>
    <a :href="t('link.cfb-site')" target="_blank" class="menu-item">
      <Button :label="`${t('menu.to-museum')}`" class="invert" />
    </a>
  </div>
</template>

<style lang="scss" scoped>
@use "@/style/base/live-color";
.main-menu {
  color: inherit;

  > * {
    display: flex;
    flex-direction: column;
    margin: 0.5rem 0;

    &:first-child {
      margin-top: 0;
    }
  }
}
</style>
