<script lang="ts">
import { defineComponent, ref, onActivated, onDeactivated, computed, watch } from "vue";
import { useWindowSize } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { useCatalogStore } from "@/stores/catalog";
import TextListWork from "./TextListWork.vue";
import eventBus from "@/eventBus";
import { useUiStore } from "@/stores/ui";

export default defineComponent({
  components: {
    TextListWork,
  },
  setup() {
    const root = ref<HTMLElement | null>(null);
    const refWorks = ref<HTMLElement | null>(null);
    const componentActive = ref(false);
    const { works, focusedWorkUid, focusedWorkIndex } = storeToRefs(useCatalogStore());
    const { loadWorks } = useCatalogStore();
    const lastScrollY = ref(0);
    const { scrollY } = storeToRefs(useUiStore());
    const { height: containerHeight } = useWindowSize();
    const workHeight = computed(() => containerHeight.value / 2);
    const focusedIndex = computed(() => {
      if (works.value.length < 1) {
        return -1;
      }
      const relPos = (scrollY.value - workHeight.value / 2) / workHeight.value;
      if (!Number.isFinite(relPos)) {
        return -1;
      }
      return Math.floor(relPos + 0.9);
    });

    onActivated(async () => {
      componentActive.value = true;
      scrollY.value = lastScrollY.value;
      await loadWorks();
      if (focusedWorkUid.value) {
        const activeWork = refWorks!.value!.childNodes.item(focusedWorkIndex.value);
        
        if (activeWork instanceof HTMLElement && activeWork !== null && activeWork.offsetTop > 0 ) {
          eventBus.emit("base:scrollTo", activeWork ? activeWork.offsetTop + 1 : 0);
        }
      }
    });

    onDeactivated(() => {
      componentActive.value = false;
      lastScrollY.value = scrollY.value;
    });

    watch(
      () => focusedIndex.value,
      (value) => {
        if (!componentActive.value) {
          return;
        }
        if (value > -1) {
          const workUid = works.value[value]?.uid ?? null;
          focusedWorkUid.value = workUid;
        }
      }
    );

    return {
      root,
      refWorks,
      works,
    };
  },
});
</script>

<template>
  <div ref="root" class="text-list">
    <div ref="refWorks" class="works">
      <TextListWork
        v-for="(work, index) in works"
        class="work"
        :key="`work-${index}`"
        :work="work"
      />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/style/abstracts/responsive";

.text-list {
  padding-bottom: 50vh;

  .works {
    padding: 0 var(--grid-gap);
  }

  .work {
    scroll-snap-align: center;
    scroll-snap-stop: normal;
    &:first-child {
      margin-top: 25vh;
    }
  }
}
</style>
