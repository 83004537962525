<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import { useViewportFocus } from "@/composables/ui";

export default defineComponent({
  emits: ["visible"],
  props: {
    tag: {
      default: "section",
    },
  },
  setup(props, { emit }) {
    const el = ref<HTMLElement | null>(null);
    const { visible } = useViewportFocus(el);

    watch(
      () => visible.value,
      (value) => {
        emit("visible", value);
      }
    );

    return {
      el,
      visible,
    };
  },
});
</script>
<template>
  <component ref="el" :is="tag">
    <slot />
  </component>
</template>

<style lang="scss" scoped>
@use "@/style/abstracts/responsive";
@use "@/style/base/typo";
@use "@/style/elements/button";

.scene {
  :deep {
    * {
      backface-visibility: hidden;
    }

    @include responsive.bp-large-up {
      scroll-snap-stop: unset;
      scroll-snap-align: unset;
    }
    > .content {
      padding: 0 var(--grid-gap);
      max-width: var(--content-max-width);
      width: 100%;
      height: 100%;
      margin: auto;

      p {
        &:after {
          display: block;
          content: "\a";
          white-space: pre;
        }
      }
    }

    &.scene--intro {
      @include responsive.bp-large-up {
        scroll-snap-stop: normal;
        scroll-snap-align: center;
      }
      > .content {
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
      }

      .title {
        @include typo.title;

        width: 100%;
        text-align: center;

        margin: 0 0 calc(2 * var(--grid-gap)) 0;
        padding-bottom: 45px;

        @include responsive.bp-large-up {
          margin: 0;
          padding-bottom: 0;
        }
      }
    }

    &.scene--visual {
      height: auto;
      margin-top: -45px;

      @include responsive.bp-large-up {
        margin-top: 0px;
        height: 60vh;
        scroll-snap-stop: normal;
        scroll-snap-align: center;
        img {
          height: 55vh;
        }
      }
    }

    &.scene--meta {
      max-height: 19.5vh;
      overflow-y: hidden;
      > * {
        @include typo.content;
      }

      .meta {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        margin: calc(2 * var(--grid-gap)) 0;

        @include responsive.bp-large-up {
          margin: calc(4 * var(--grid-gap)) 0;
        }
      }
    }

    &.scene--rich-media {
      > * {
        @include typo.content;

        p {
          &:first-child {
            &:after {
              display: flex;
              content: "";
              height: 1px;
              width: 33.333vw;
              background-color: rgb(var(--c-fg));
              margin: calc(1.5 * var(--t-lh-large)) auto;
              @include responsive.bp-large-up {
                height: 2px;
                width: 33.333%;
              }
            }
          }
        }
      }
    }
  }
}
</style>
