<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import type { PropType } from "vue";
import type { Work } from "@/typings/api";
import { useRouter } from "vue-router";
import Visual from "@/components/ui/Visual.vue";
import { useElementBounding, useWindowSize } from "@vueuse/core";

export default defineComponent({
  components: { Visual },
  props: {
    work: {
      type: Object as PropType<Work>,
      required: true,
    },
    animationDuration: {
      type: Number,
      default: 200,
    },
  },
  setup(props) {
    const { t, locale: i18nLocale } = useI18n();
    const locale = computed(() => {
      // TODO: handle default language
      return i18nLocale.value === "de" ? "" : i18nLocale.value;
    });
    const router = useRouter();
    const el = ref<HTMLElement | null>(null);
    const { width: elW, height: elH, top: elTop, left: elLeft } = useElementBounding(el);
    const { width: vW, height: vH } = useWindowSize();
    const hasAudio = computed(() => !!(props.work.audios && props.work.audios.length));
    const playAudio = () => {};

    const artworkAltText = computed(() => {
      return `${props.work.artist?.name} - ${props.work.title}`;
    });

    const artworkCenteredPosition = computed(() => {
      const relLeft = (vW.value - elW.value) / 2;
      const relTop = (vH.value - elH.value) / 2;
      return { relLeft, relTop };
    });

    const navigate = () => {
      router.push({
        name: "workDetail",
        params: {
          locale: locale.value,
          uid: props.work.uid,
          transition: "reveal",
        },
      });
    };

    return {
      t,
      locale,
      el,
      artworkAltText,
      hasAudio,
      playAudio,
      navigate,
      elTop,
      elLeft,
      artworkCenteredPosition,
    };
  },
});
</script>

<template>
  <div v-if="work" ref="el" class="artwork">
    <a v-touch:tap="navigate" @click.prevent>
      <Visual
        v-if="work.image"
        :image="work.image"
        resize="scale"
        :max-width="500"
        :alt-text="artworkAltText"
      />
    </a>
  </div>
</template>

<style lang="scss" scoped>
@use "@/style/abstracts/responsive";

.artwork {
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
  margin: auto;
  width: 100%;
  position: relative;
  overflow: hidden;

  transform: scale(1);
  transition: transform 250ms ease-out;

  @include responsive.on-hover {
    z-index: 1;
    transform: scale(1.1);
  }

  > div {
    display: flex;
    width: 100%;
    margin: auto;
  }

  a {
    display: flex;
    margin: auto;
    width: 100%;

    img {
      width: 100%;
      height: auto;
      margin: auto;
      object-fit: contain;
      max-height: 200px;
    }
  }
}
</style>
