<script lang="ts">
import { defineComponent, onActivated } from "vue";
import { useWindowSize } from "@vueuse/core";
import { storeToRefs } from "pinia";
import { useCatalogStore } from "@/stores/catalog";
import TextList from "@/components/catalog/work/TextList.vue";

export default defineComponent({
  components: {
    TextList,
  },
  setup() {
    const { works } = storeToRefs(useCatalogStore());
    const { loadWorks } = useCatalogStore();
    const { width: vpWidth, height: vpHeight } = useWindowSize();
    onActivated(() => loadWorks());
    return {
      vpWidth,
      vpHeight,
      works,
    };
  },
});
</script>

<template>
  <div ref="root" class="work-list story-first">
    <TextList />
  </div>
</template>

<style lang="scss" scoped>
.work-list {
  height: 100%;
}
</style>
