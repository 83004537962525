<script lang="ts">
import { defineComponent, computed } from "vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  props: {
    path: {
      type: String,
      default: "",
    },
  },
  components: {},
  setup() {
    const { t, locale: i18nLocale } = useI18n();
    const locale = computed(() => {
      return i18nLocale.value === "de" ? "" : i18nLocale.value;
    });
    return {
      t,
      locale,
    };
  },
});
</script>

<template>
  <section class="page">
    <div class="content">
      <header>
        <h1 class="title" v-text="t('page.notfound.title')" />
      </header>
      <section>
        <div class="body">
          <p v-text="path" />
          <router-link
            :to="{ name: 'storyFirst', params: { locale } }"
            v-text="t('page.notfound.home')"
          />
        </div>
      </section>
    </div>
  </section>
</template>

<style lang="scss" scoped>
@use "@/style/abstracts/responsive";
@use "@/style/base/typo";
@use "@/style/elements/link";

.page {
  * {
    @include typo.content-alt;
  }

  padding: var(--nav-height) var(--grid-gap);

  .content {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
    margin: auto;
    max-width: var(--content-max-width);

    .title {
      @include typo.title;

      display: flex;
      justify-content: center;
      align-items: center;
      margin-bottom: var(--nav-height);
      text-transform: uppercase;
    }

    .body {
      display: flex;
      flex-direction: column;
      margin-bottom: calc(2 * var(--grid-gap));

      @include responsive.bp-medium-up {
        justify-content: center;
        align-items: center;
      }

      p {
        text-transform: uppercase;
        padding-bottom: var(--grid-gap);
      }

      a {
        @include link.secondary;
      }
    }
  }
}
</style>
