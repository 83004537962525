<script lang="ts">
import { computed, defineComponent, onActivated, onDeactivated, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { storeToRefs } from "pinia";
import RuntimeTemplate from "vue3-runtime-template";
import { useViewportFocus } from "@/composables/ui";
import { useDocumentTitle } from "@/composables/meta";
import { useCatalogStore } from "@/stores/catalog";
import eventBus from "@/eventBus";
import NotFound from "@/views/NotFound.vue";
import RichMediaPanel from "@/components/catalog/work/detail/rich-media/RichMediaPanel.vue";
import WorkVisual from "@/components/catalog/work/detail/WorkVisual.vue";
import WorkFooter from "@/components/catalog/work/detail/WorkFooter.vue";
import WorkScene from "@/components/catalog/work/detail/WorkScene.vue";
import { useUiStore } from "@/stores/ui";

const MAX_WEIGHT = 900;

export default defineComponent({
  components: {
    NotFound,
    WorkScene,
    WorkFooter,
    WorkVisual,
    RichMediaPanel,
    RuntimeTemplate,
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const title = ref<HTMLElement | null>(null);
    const visual = ref<HTMLElement | null>(null);
    const { focusValue: titleFocusValue, inFocus: titleInFocus } = useViewportFocus(title);
    const { works, focusedWorkUid } = storeToRefs(useCatalogStore());
    const worksLoaded = ref(false);
    const work = computed(() => works.value.find((w) => w.uid === props.uid));
    const componentActive = ref(false);
    const scrollActivated = ref(false);
    const { loadWorks } = useCatalogStore();
    const { scrollY, isScrolling } = storeToRefs(useUiStore());

    watch(
      () => props.uid,
      (value) => {
        focusedWorkUid.value = value;
      }
    );

    const documentTitle = computed(() => work.value?.title ?? "");
    useDocumentTitle(documentTitle);

    //activate focus behaviour for title after first scrolling
    watch(
      () => isScrolling.value,
      () => {
        if (!scrollActivated.value) {
          scrollActivated.value = true;
        }
      }
    );

    const titleStyle = computed(() => {
      if (scrollActivated.value) {
        const weight = Math.round(400 + (MAX_WEIGHT - 400) * titleFocusValue.value);
        const scale = 1 - 0.35 * (1 - titleFocusValue.value);

        return {
          fontVariationSettings: `"wght" ${weight}`,
          transform: `scale(${scale})`,
          transitionDuration: "0ms",
        };
      }
      return {
        transform: "",
      };
    });

    const showVisual = computed(() => {
      return scrollY.value > 120;
    });

    const showScroll = computed(() => {
      return scrollY.value < 50;
    });

    //RichMediaLookup & Panel
    const richMediaVisible = ref(false);
    const onRichMediaVisible = (e: any) => {
      richMediaVisible.value = e;
    };
    const richMediaTitle = ref("");
    const richMediaLookup = ref("");
    const showRichMediaPanel = ref(false);

    //initial states & transitions
    onActivated(async () => {
      await loadWorks();
      worksLoaded.value = true;
      focusedWorkUid.value = props.uid;
      componentActive.value = true;

      eventBus.on("objRef:show", (e) => {
        const { title: panelTitle, lookup } = e;
        richMediaTitle.value = panelTitle;
        richMediaLookup.value = lookup;

        if (showRichMediaPanel.value) {
          showRichMediaPanel.value = false;
          setTimeout(() => {
            showRichMediaPanel.value = true;
            richMediaTitle.value = panelTitle;
            richMediaLookup.value = lookup;
          }, 500);
        } else {
          showRichMediaPanel.value = true;
          richMediaTitle.value = panelTitle;
          richMediaLookup.value = lookup;
        }
      });

      eventBus.on("objRef:hide", () => {
        showRichMediaPanel.value = false;
      });

      setTimeout(() => {
        scrollY.value = 0;
      }, 500);
    });

    onDeactivated(() => {
      componentActive.value = false;
      scrollActivated.value = false;

      eventBus.off("objRef:show");
      eventBus.off("objRef:hide");

      setTimeout(() => {
        scrollActivated.value = false;
      }, 350);
    });

    return {
      t,
      work,
      worksLoaded,
      title,
      visual,

      componentActive,
      titleStyle,
      titleInFocus,
      showVisual,
      showScroll,

      //RichMedia
      onRichMediaVisible,
      richMediaVisible,
      richMediaTitle,
      richMediaLookup,
      showRichMediaPanel,
    };
  },
});
</script>

<template>
  <NotFound v-if="worksLoaded && !work" :path="`work/${uid}/`" />
  <div
    v-else
    class="work-detail"
    :class="[
      { 'show-rich-media-panel': showRichMediaPanel },
      { 'rich-media-visible': richMediaVisible },
    ]"
  >
    <div class="scenes">
      <WorkScene class="scene scene--intro" tag="header">
        <div class="content">
          <transition name="title">
            <h1
              v-if="componentActive"
              ref="title"
              class="title sticky"
              v-text="work?.title"
              :style="titleStyle"
            />
          </transition>
          <transition name="fade" appear>
            <div v-if="showScroll" class="scroll-indicator">scroll</div>
          </transition>
        </div>
      </WorkScene>
      <WorkScene class="scene scene--visual">
        <div class="content">
          <div ref="visual" class="visual">
            <XyzTransition xyz="fade in-up-1 small out-down-1 ">
              <div v-show="showVisual">
                <WorkVisual
                  v-if="work?.image"
                  :image="work?.image"
                  :max-width="1200"
                  :alt-text="work?.artist.displayName"
                />
              </div>
            </XyzTransition>
          </div>
        </div>
      </WorkScene>
      <WorkScene class="scene scene--meta">
        <div class="content">
          <div class="meta">
            <h2 v-if="work?.artist" class="artist" v-text="work?.artist.displayName" />
            <h3 v-if="work?.yearOfOrigin" class="year-of-origin" v-text="work?.yearOfOrigin" />
          </div>
        </div>
      </WorkScene>
      <WorkScene class="scene scene--rich-media" @visible="onRichMediaVisible">
        <div class="content">
          <div class="rich-text">
            <div class="body">
              <RuntimeTemplate :template="work?.body" />
            </div>
            <RichMediaPanel
              :title="richMediaTitle"
              :lookup="richMediaLookup"
              :visible="showRichMediaPanel"
            />
          </div>
        </div>
      </WorkScene>
      <WorkFooter :work="work" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/style/abstracts/responsive";
@use "@/style/base/typo";
@use "@/style/elements/button";

//TODO: remove --vh
.work-detail {
  --sticky-offset: 2rem;
  z-index: 2;

  > * {
    margin: auto;
  }

  .scroll-indicator {
    @include typo.default;

    position: absolute;
    bottom: calc(2 * var(--grid-gap) + var(--audio-player-height));
    margin: auto;
    transform: translateX(-50%);
    left: 50%;
    text-transform: uppercase;
  }

  //move content to left when displaying rich media panels
  .scenes {
    transition: transform 350ms ease-out;
  }
  &.show-rich-media-panel {
    @include responsive.bp-large-up {
      .scenes {
        transform: translateX(calc(-20vw + 7.5rem));
      }
    }

    //set background color for non-active obj-ref links  (open rich-media-panel)
    :deep {
      .obj-ref {
        &:not(.active) {
          &:after {
            background-color: rgb(var(--c-interface-bg));
          }
        }
      }
    }
  }

  //fix scroll position jitter with programmatically closed rich media panels
  &.rich-media-visible {
    :deep(.scene) {
      @include responsive.bp-large-up {
        scroll-snap-stop: unset;
        scroll-snap-align: unset;
      }
    }
  }

  .scene {
    //sticky scenes
    .sticky {
      position: sticky;
      top: var(--nav-height);
      padding-top: var(--sticky-offset);

      @include responsive.bp-large-up {
        top: var(--grid-gap);
      }
    }
    &--intro {
      .title {
        /* fade transitions */
        &.title-enter-active,
        &.title-leave-active {
          transition: font-variation-settings 500ms ease-out 500ms, opacity 1000ms ease-out;
        }

        &.title-enter-from,
        &.title-leave-to {
          font-variation-settings: "wght" 100;
          opacity: 0;
        }

        &.title-enter-to {
          opacity: 1;
          font-variation-settings: "wght" 900;
        }
      }
    }
    &--meta {
      .meta {
        * {
          @include typo.content;
        }
      }
    }
  }
}

/* fade transitions */
.fade-enter-active,
.fade-leave-active {
  transition: opacity 350ms ease-out;
}

.fade-enter-from,
.fade-leave-to {
  opacity: 0;
  transform: translateY(-50px);
}

.fade-enter-to {
  opacity: 1;
  transform: translateY(0px);
}
</style>
