import mitt from "mitt";

type Events = {
  // obj-ref
  "objRef:show": {
    lookup: string;
    title: string;
  };
  "objRef:hide": void;
  // ui
  "base:scrollTo": Number;
  // audio-player
  "audioPlayer:startsPlaying": void;
  "inlineAudioPlayer:startsPlaying": void;
  // video-player
  "videoPlayer:startsPlaying": void;
};

const eventBus = mitt<Events>();

export default eventBus;
