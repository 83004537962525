<script lang="ts">
import { defineComponent } from "vue";
import AudioPlayer from "@/components/audio-player/AudioPlayer.vue";
import { useWindowSize } from "@vueuse/core";

export default defineComponent({
  name: "App",
  components: {
    AudioPlayer,
  },
  setup() {
    const { height: windowHeight } = useWindowSize();
    return {
      windowHeight,
    };
  },
});
</script>

<template>
  <!-- TODO: --vh needs to be set toplevel div (needed for mobile fixed height panels) -->
  <div
    class="app-base"
    :style="{
      '--vh': `${windowHeight / 100}px`,
    }"
  >
    <router-view v-slot="{ Component }">
      <keep-alive>
        <component :is="Component" />
      </keep-alive>
    </router-view>
    <div id="objRefTarget"></div>
    <AudioPlayer />
  </div>
</template>
