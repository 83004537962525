<script lang="ts">
import { defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import Button from "@/components/ui/button/Button.vue";

export default defineComponent({
  components: {
    Button,
  },
  setup() {
    const root = ref<HTMLElement | null>(null);
    const { t } = useI18n();

    return {
      root,
      t,
    };
  },
});
</script>
<template>
  <div ref="root" class="inline-content">
    <div class="content content--collection">
      <div class="title" v-text="t('inline-content-about.collection-title')" />
      <div class="body" v-text="t('inline-content-about.collection')" />
    </div>
    <div class="content content--collection-online">
      <div class="title" v-text="t('inline-content-about.collection-online-title')" />
      <div class="body" v-text="t('inline-content-about.collection-online')" />
    </div>
    <div class="content content--social">
      <div class="title" v-text="t('inline-content-about.social-title')" />
      <div class="body">
        <div class="services">
          <a :href="`${t('link.cfb-instagram')}`">
            <Button label="Instagram" class="invert" />
          </a>
          <a :href="`${t('link.cfb-facebook')}`">
            <Button label="Facebook" class="invert" />
          </a>
          <a :href="`${t('link.cfb-twitter')}`">
            <Button label="Twitter" class="invert" />
          </a>
          <a :href="`${t('link.cfb-youtube')}`">
            <Button label="Youtube" class="invert" />
          </a>
        </div>
        <div class="newsletter">
          <a :href="`${t('link.cfb-newsletter')}`">
            <Button label="Newsletter" class="invert" />
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/style/abstracts/responsive";
@use "@/style/base/typo";
@use "@/style/elements/button";

.inline-content {
  display: flex;
  flex-direction: column;

  .content {
    display: flex;
    flex-direction: column;
    grid-gap: var(--grid-gap);
    margin-bottom: calc(3 * var(--grid-gap));

    @include responsive.bp-large-up {
      display: grid;
      grid-template-columns: 1fr 1fr;
      margin-bottom: calc(2 * var(--grid-gap));
    }

    &--social {
      .services {
        @include button.group;

        margin-bottom: var(--grid-gap);
      }
    }
  }
}
</style>
