<script lang="ts">
import { defineComponent } from "vue";
import ImageList from "@/components/catalog/work/ImageList.vue";

export default defineComponent({
  components: {
    ImageList,
  },
});
</script>

<template>
  <div ref="root" class="work-list artwork-first">
    <ImageList />
  </div>
</template>

<style lang="scss" scoped>
.work-list {
  height: 100%;
}
</style>
