<script lang="ts">
import { defineComponent, ref, watch } from "vue";
import Button from "@/components/ui/button/Button.vue";
import TransitionExpand from "@/components/ui/transition/TransitionExpand.vue";
import uniqueId from "lodash/uniqueId";

export default defineComponent({
  components: { TransitionExpand, Button },
  props: {
    label: {
      type: String,
      default: "",
    },
    activePanelId: {
      type: String,
      default: "",
    },
  },
  emits: ["open"],
  setup(props, { emit }) {
    const el = ref<HTMLElement | null>(null);
    const isOpen = ref(false);
    const id = uniqueId();

    const togglePanel = () => {
      isOpen.value = !isOpen.value;
      if (isOpen.value) {
        emit("open", id);
      }
    };

    watch(
      () => props.activePanelId,
      () => {
        if (props.activePanelId !== id) {
          isOpen.value = false;
        }
      }
    );

    return {
      el,
      isOpen,
      togglePanel,
    };
  },
});
</script>

<template>
  <div ref="el" class="main-menu-panel">
    <Button v-touch:tap="togglePanel" :label="label" :class="{ 'is-active': isOpen }" />
    <TransitionExpand :duration="350">
      <div v-if="isOpen" class="panel">
        <slot name="default" />
      </div>
    </TransitionExpand>
  </div>
</template>

<style lang="scss" scoped>
@use "@/style/abstracts/responsive";
@use "@/style/base/typo";

.main-menu-panel {
  @include typo.nav;

  .panel {
    @include typo.base;
    @include typo.nav;

    height: auto;
    padding: calc(var(--grid-gap) / 2) var(--grid-gap);
    max-height: calc(
      (var(--vh) * 100) - 168px
    ); //depends on available height -> amount menu entries
    overflow-y: scroll;

    :deep(> *) {
      &:first-child {
        margin-top: calc(2 * var(--grid-gap));
      }
    }

    @include responsive.bp-large-up {
      max-height: calc(
        (var(--vh) * 100) - 212px
      ); //depends on available height -> amount menu entries
    }
  }
}
</style>
