<script lang="ts">
import { defineComponent, ref, watch, onMounted, computed } from "vue";
import type { Artist } from "@/typings/api";
import showdown from "showdown";
import { getArtist } from "@/api/catalog";
import Visual from "@/components/ui/Visual.vue";

const converter = new showdown.Converter();
export default defineComponent({
  components: {
    Visual,
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const obj = ref<Artist | null>(null);
    const loadObj = async () => {
      obj.value = await getArtist(props.uid);
    };
    watch(() => props.uid, loadObj);
    onMounted(loadObj);
    const body = computed(() => {
      const raw = obj.value?.body ?? "";
      return converter.makeHtml(raw);
    });
    return {
      obj,
      body,
    };
  },
});
</script>

<template>
  <div class="artist">
    <div v-if="obj?.image">
      <Visual :image="obj.image" :max-width="800" :animation="false" :max-ratio="1.5" />
      <span v-if="obj?.image?.credits" v-text="obj.image.credits" />
    </div>
    <p v-if="obj?.body" class="type" v-text="obj.body" />
  </div>
</template>

<style lang="scss" scoped>
@use "@/style/base/typo";

.artist {
  @include typo.content-alt-aux;
}
</style>
