<script lang="ts">
import { computed, defineComponent, onMounted, ref, watch } from "vue";
import showdown from "showdown";
import type { WorkText } from "@/typings/api";
import { getWorkText } from "@/api/catalogRichMedia";
import Button from "@/components/ui/button/Button.vue";

const converter = new showdown.Converter();

export default defineComponent({
  components: {
    Button,
  },
  props: {
    uid: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const obj = ref<WorkText | null>(null);
    const loadObj = async () => {
      obj.value = await getWorkText(props.uid);
    };
    watch(() => props.uid, loadObj);
    onMounted(loadObj);
    const body = computed(() => {
      const raw = obj.value?.body ?? "";
      return converter.makeHtml(raw);
    });
    return {
      obj,
      body,
    };
  },
});
</script>

<template>
  <div class="work-text">
    <div v-if="body" class="body" v-html="body" />
    <a v-if="obj?.link" :href="obj?.link" target="_blank">
      <Button :label="obj?.linkTitle || obj?.link" class="invert" />
    </a>
  </div>
</template>

<style lang="scss" scoped>
@use "@/style/base/typo";

.work-text {
  @include typo.content-alt-aux;
  .link {
    margin-top: 1rem;
  }
}
</style>
