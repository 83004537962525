<script lang="ts">
import type { Work, Artist } from "@/typings/api";
import { computed, defineComponent } from "vue";
import { uniq } from "lodash-es";
import { storeToRefs } from "pinia";
import { useCatalogStore } from "@/stores/catalog";
import IndexGroup from "./IndexGroup.vue";

export type AnnotatedArtist = Artist & {
  works: Work[];
};

type ArtistGroup = {
  char: string;
  artists: AnnotatedArtist[];
};

export default defineComponent({
  components: {
    IndexGroup,
  },
  setup() {
    const { works, artists: artistList } = storeToRefs(useCatalogStore());
    const artists = computed(() => {
      return artistList.value.map((a: Artist) => {
        const mapped: AnnotatedArtist = {
          ...a,
          works: works.value.filter((w: Work) => {
            return w.artist.uid === a.uid;
          }),
        };
        return mapped;
      });
    });
    const groupedArtists = computed(() => {
      const groups: ArtistGroup[] = [];
      const chars = uniq(artists.value.map((a) => a.name?.charAt(0)));
      // @ts-ignore
      chars.forEach((char: string) => {
        const group: ArtistGroup = {
          char,
          artists: artists.value.filter((a: Artist) => a.name?.charAt(0) === char),
        };
        groups.push(group);
      });
      return groups;
    });
    return {
      works,
      artists,
      groupedArtists,
    };
  },
});
</script>

<template>
  <div class="index">
    <IndexGroup
      v-for="(group, i) in groupedArtists"
      :key="`artist-index-group-${i}`"
      :char="group.char"
      :artists="group.artists"
    />
  </div>
</template>

<style lang="scss" scoped>
@use "@/style/abstracts/responsive";

.index {
  display: flex;
  flex-direction: column;
  grid-row-gap: calc(var(--grid-gap) / 2);

  @include responsive.bp-large-up {
    grid-row-gap: var(--grid-gap);
  }
}
</style>
