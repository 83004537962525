<script lang="ts">
import { computed, defineComponent, ref, onUpdated } from "vue";
import { round } from "lodash-es";
import { useElementSize } from "@vueuse/core";

const alphabet = [
  "a",
  "b",
  "c",
  "d",
  "e",
  "f",
  "g",
  "h",
  "i",
  "j",
  "k",
  "l",
  "m",
  "n",
  "o",
  "p",
  "q",
  "r",
  "s",
  "t",
  "u",
  "v",
  "w",
  "x",
  "y",
  "z",
  ".",
  "?",
  "!",
  " ",
  ":",
  ";",
  "/",
  "-",
  "",
];

const Char = defineComponent({
  props: {
    char: {
      type: String,
    },
  },
  emits: ["rendered"],
  setup(props, { emit }) {
    const el = ref(null);
    const { width, height } = useElementSize(el);
    const ratio = computed(() => {
      return round(width.value / height.value, 3);
    });
    onUpdated(() => {
      emit("rendered", props.char, ratio.value);
    });
    return {
      el,
      width,
      height,
      ratio,
    };
  },
  template: `
  <div>
    <div ref="el" class="char" v-text="char" />
    <span style="display: none;" v-text="ratio " />
  </div>`,
});

export default defineComponent({
  components: {
    Char,
  },
  setup() {
    const charsUpper = alphabet;
    const charsLower = alphabet.map((c) => c.toUpperCase());
    const map = ref({});
    const fWeight = ref(400);
    const style = computed(() => {
      return {
        fontVariationSettings: `"wght" ${fWeight.value}`,
      };
    });
    const onRendered = (char: string, ratio: number) => {
      // @ts-ignore
      map.value[char] = ratio;
    };
    return {
      fWeight,
      style,
      charsUpper,
      charsLower,
      onRendered,
      map,
    };
  },
});
</script>

<template>
  <div class="ptype">
    <div class="controls">
      <input type="range" v-model="fWeight" min="100" max="1000" step="50" />
      <div v-text="fWeight" />
    </div>
    <div :style="style" class="chars chars--lower">
      <Char v-for="char in charsUpper" :key="`uc-${char}`" :char="char" @rendered="onRendered" />
    </div>
    <div :style="style" class="chars chars--upper">
      <Char v-for="char in charsLower" :key="`lc-${char}`" :char="char" @rendered="onRendered" />
    </div>
    <div class="map">
      <pre v-text="map" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
.ptype {
  padding-top: 64px;
  .controls {
    margin: 20px 0 40px;
  }
  .chars {
    display: flex;
    font-size: 100px;
    > div {
    }
  }
  .map {
    margin: 20px 0;
    font-size: 12px;
  }
}
</style>
