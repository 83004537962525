<script lang="ts">
import type { PropType } from "vue";
import type { WorkAudio } from "@/typings/api";
import { computed, defineComponent, ref, watch, onBeforeUnmount } from "vue";
import { useI18n } from "vue-i18n";
import { round } from "lodash-es";
import { Howl } from "howler";
import { useRafFn, watchDebounced } from "@vueuse/core";
import eventBus from "@/eventBus";
import Duration from "@/components/ui/time/Duration.vue";
import Playhead from "./Playhead.vue";

enum State {
  STOPPED = "stopped",
  PLAYING = "playing",
  PAUSED = "paused",
}

export default defineComponent({
  components: {
    Duration,
    Playhead,
  },
  props: {
    audio: {
      type: Object as PropType<WorkAudio>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const player = ref<Howl | null>(null);
    const playerState = ref(State.STOPPED);
    const debouncedPlayerState = ref(State.STOPPED);
    const playerPosition = ref(0);
    const playerDuration = ref(-1);
    const credits = computed(() => (props.audio ? props.audio.credits : ""));

    watchDebounced(
      playerState,
      (value: State) => {
        debouncedPlayerState.value = value;
      },
      {
        debounce: 100,
        maxWait: 200,
      }
    );

    const duration = computed(() => {
      // return actual value from player, else pre-calculated value from API
      if (playerDuration.value > -1) {
        return playerDuration.value;
      }
      return props.audio ? props.audio.duration : 0;
    });

    const timeLeft = computed(() => {
      if (!duration.value) {
        return 0;
      }
      return duration.value - playerPosition.value;
    });

    const progress = computed(() => {
      if (!playerDuration.value) {
        return 0;
      }
      return round(playerPosition.value / playerDuration.value, 3);
    });

    const updatePlayerState = () => {
      if (!player.value) {
        return;
      }
      playerDuration.value = player.value.duration();
      playerPosition.value = round(player.value.seek(), 3);
    };

    const play = (relPos = 0) => {
      const src = `/media/${props.audio.path}`;
      if (player.value) {
        player.value.unload();
      }
      const p = new Howl({
        src: src,
        html5: true,
        format: ["mp3"],
      });
      p.on("play", () => {
        playerState.value = State.PLAYING;
      });
      p.on("stop", () => {
        playerState.value = State.STOPPED;
      });
      p.on("pause", () => {
        playerState.value = State.PAUSED;
      });
      p.on("end", () => {
        playerState.value = State.STOPPED;
        playerPosition.value = 0;
        playerDuration.value = -1;
      });
      p.play();
      p.once("play", () => {
        if (relPos) {
          const d = props.audio?.duration ?? 0;
          const absPos = d * relPos;
          p.seek(absPos);
        }
      });
      player.value = p;
    };

    const pause = () => {
      if (player.value && playerState.value === State.PLAYING) {
        player.value.pause();
      }
    };

    const resume = () => {
      if (player.value) {
        player.value.play();
      }
    };

    const stop = () => {
      if (player.value) {
        player.value.fade(1, 0, 100);
        setTimeout(() => {
          if (player.value) {
            player.value.stop();
            player.value.volume(1);
          }
          playerPosition.value = 0;
          playerDuration.value = -1;
        }, 100);
      }
    };

    const seek = async (to: number) => {
      const relPos = to;
      const absPos = playerDuration.value * to;
      if (playerState.value === State.STOPPED) {
        play(relPos);
      } else if (playerState.value === State.PAUSED) {
        if (player.value) {
          player.value.play();
          player.value.once("play", () => {
            if (player.value) {
              player.value.seek(absPos);
            }
          });
        }
      } else if (player.value) {
        player.value.seek(absPos);
      }
    };

    const { pause: pausePlayerUpdate, resume: resumePlayerUpdate } = useRafFn(() => {
      updatePlayerState();
    });

    watch(
      () => playerState.value,
      (value) => {
        if (value === State.PLAYING) {
          resumePlayerUpdate();
          eventBus.emit("inlineAudioPlayer:startsPlaying");
        } else {
          pausePlayerUpdate();
        }
      }
    );

    // stop on unmount
    onBeforeUnmount(stop);

    // stop if primary player starts
    eventBus.on("audioPlayer:startsPlaying", pause);

    return {
      t,
      State,
      playerState,
      debouncedPlayerState,
      progress,
      duration,
      timeLeft,
      play,
      pause,
      resume,
      seek,
      credits,
    };
  },
});
</script>

<template>
  <div class="audio-player">
    <div class="actions">
      <transition name="actions" mode="out-in">
        <div
          v-if="debouncedPlayerState === State.STOPPED"
          @click.prevent="play(0)"
          class="action action--play"
          v-text="t('player.play')"
        />
        <div
          v-else-if="debouncedPlayerState === State.PAUSED"
          @click.prevent="resume"
          class="action action--resume"
          v-text="t('player.resume')"
        />
        <div
          v-else-if="debouncedPlayerState === State.PLAYING"
          @click.prevent="pause"
          v-text="t('player.pause')"
          class="action action--pause"
        />
      </transition>
    </div>
    <div class="playhead">
      <Playhead @seek="seek" :progress="progress" :can-seek="playerState !== State.STOPPED" />
    </div>
    <div class="position">
      <Duration class="position" :seconds="timeLeft" prefix="-" />
    </div>
  </div>
  <div v-if="credits" class="credits" v-text="credits" />
</template>

<style lang="scss" scoped>
@use "@/style/abstracts/responsive";
@use "@/style/base/typo";

.audio-player {
  @include typo.content-alt;

  background-color: rgb(var(--c-primary));
  border-radius: var(--border-radius);
  height: var(--button-size);
  display: grid;
  grid-column-gap: calc(var(--grid-gap) / 2);
  grid-template-columns: 80px 1fr 80px;
  align-items: center;
  width: 100%;
  > * {
    &:first-child {
      padding-left: var(--grid-gap);
    }
    &:last-child {
      padding-right: var(--grid-gap);
    }
  }

  @include responsive.bp-large-up {
    grid-template-columns: 100px 1fr 100px;
  }
  .actions {
    .action {
      cursor: pointer;
      text-transform: uppercase;
      &--disabled {
        opacity: 0.5;
        cursor: default;
      }
      @include responsive.on-hover {
        opacity: 0.8;
      }
    }
  }
  .position {
    display: flex;
    justify-content: flex-end;
  }
}
.credits {
  @include typo.meta;

  padding-top: var(--grid-gap);
}

// actions
.actions-enter-active,
.actions-leave-active {
  transition: opacity 150ms;
  opacity: 1;
}
.actions-enter-from,
.actions-leave-to {
  opacity: 0;
}
</style>
