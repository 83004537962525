export interface Settings {
  DEBUG: boolean;
  STATIC_URL: string;
  IMAGE_RESIZER_ENDPOINT: string;
  API_BASE_URL: string;
  SENTRY_DSN: string;
}

declare global {
  interface Document {
    settings: any;
  }
}

const { settings } = document;

const env = import.meta.env;

const appSettings = {
  ...settings,
  ...env,
};

export default appSettings;
