<script lang="ts">
import { defineComponent, computed } from "vue";
import { useRoute } from "vue-router";

export default defineComponent({
  props: {
    network: {
      type: String,
      default: "",
    },
    title: {
      type: String,
      default: "",
    },
  },
  setup() {
    const route = useRoute();
    const url = computed(() => {
      return new URL(route.path, window.location.origin).href;
    });
    return {
      url,
    };
  },
});
</script>

<template>
  <ShareNetwork :network="network" :title="title" :url="url">
    <slot name="default">{{ network }}</slot>
  </ShareNetwork>
</template>
