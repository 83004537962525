<script lang="ts">
import { defineComponent, ref, onActivated, onDeactivated, computed } from "vue";
import { storeToRefs } from "pinia";
import { useCatalogStore } from "@/stores/catalog";
import ImageListWork from "./ImageListWork.vue";
import eventBus from "@/eventBus";

export default defineComponent({
  components: {
    ImageListWork,
  },
  setup() {
    const root = ref<HTMLElement | null>(null);
    const refWorks = ref<HTMLElement | null>(null);
    const componentActive = ref(false);
    const { works, focusedWorkIndex } = storeToRefs(useCatalogStore());
    const { loadWorks } = useCatalogStore();

    const workList = computed(() => {
      return [...works.value].reverse();
    });

    onActivated(async () => {
      componentActive.value = true;
      await loadWorks();
      if (focusedWorkIndex.value && refWorks.value) {
        const activeWork = refWorks?.value.childNodes.item(focusedWorkIndex.value);
        if (activeWork instanceof HTMLElement && activeWork !== null) {
          eventBus.emit("base:scrollTo", activeWork ? activeWork.offsetTop : 0);
        }
      }
    });

    onDeactivated(() => {
      componentActive.value = false;
    });

    return {
      root,
      refWorks,
      workList,
    };
  },
});
</script>

<template>
  <div ref="root" class="image-list">
    <div class="works">
      <div ref="refWorks" class="list display-grid">
        <ImageListWork
          v-for="(work, index) in workList"
          class="work"
          :key="`work-${index}`"
          :work="work"
        />
      </div>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/style/abstracts/responsive";

.image-list {
  .works {
    margin-top: var(--nav-height);
    padding: 0 var(--grid-gap);

    .list {
      overflow: visible;
      display: grid;
      grid-auto-rows: min-content;
      grid-auto-flow: dense;
      margin: auto;
      max-width: var(--content-max-width);
      grid-template-columns: repeat(auto-fit, minmax(min(80px, 100%), 1fr));
      padding-bottom: calc(var(--nav-height) + var(--audio-player-height));
      grid-gap: var(--grid-gap);

      @include responsive.bp-medium-up {
        grid-template-columns: repeat(auto-fit, minmax(min(140px, 100%), 1fr));
      }

      @include responsive.bp-large-up {
        grid-template-columns: repeat(auto-fit, minmax(min(180px, 100%), 1fr));
      }

      @include responsive.bp-max-up {
        grid-template-columns: repeat(auto-fit, minmax(min(220px, 100%), 1fr));
      }
    }
  }
}
</style>
