<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import { useI18n } from "vue-i18n";
import Button from "@/components/ui/button/Button.vue";
import IconExhibition from "@/components/ui/icon/IconExhibition.vue";
import Share from "@/components/catalog/work/detail/Share.vue";
import LogoFB from "@/assets/image/logo-fb.svg";
import type { Work } from "@/typings/api";

export default defineComponent({
  components: {
    Button,
    IconExhibition,
    Share,
  },
  props: {
    work: {
      type: Object as () => Work,
    },
  },
  setup(props) {
    const root = ref<HTMLElement | null>(null);
    const { t } = useI18n();

    const exhibition = computed(() => {
      const exhibitions = props.work?.exhibitions ?? [];
      return exhibitions.find((e: any) => e.fbExhibitionUrl) || null;
    });

    return {
      root,
      t,
      exhibition,
      LogoFB,
    };
  },
});
</script>
<template>
  <footer class="scene-footer">
    <div class="content">
      <div class="footer">
        <div class="credits">
          <div class="header" v-text="t('work.title-credits')" />
          <div class="work-credits">
            <span v-if="work?.artist" v-text="work.artist.displayName" />
            <div>
              <span v-text="work?.title" />
              <span v-if="work?.yearOfOrigin">, {{ work.yearOfOrigin }}</span>
            </div>
            <div>
              <span v-if="work?.technique" v-text="work.technique" />
              <span v-if="work?.dimensions">
                <template v-if="work?.technique">, </template>
                {{ work.dimensions }}
              </span>
            </div>
          </div>
          <div class="aux-credits" v-if="work?.credits">
            <span v-text="work.credits" />
          </div>
          <div class="image-credits" v-if="work?.image?.credits">
            <span v-text="work?.image.credits" />
          </div>
        </div>
        <div class="explore">
          <div class="social">
            <div class="header" v-text="t('work.title-share')" />
            <Share v-if="work" :work="work" />
          </div>
          <div v-if="exhibition || work?.fbCollectionUrl" class="exhibition">
            <div class="header" v-if="exhibition">
              <IconExhibition />
            </div>
            <div class="content button-group stacked" :class="{ 'has-extra-gap': !exhibition }">
              <a v-if="exhibition" :href="exhibition?.fbExhibitionUrl" target="_blank">
                <Button :label="`${t('work.current-exhibition')}`" class="invert" />
              </a>
              <a v-if="work?.fbCollectionUrl" :href="work?.fbCollectionUrl" target="_blank">
                <Button :label="`${t('work.view-on-site')}`" class="invert" />
              </a>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-brand">
        <a class="brand" :href="`${t('link.cfb-site')}`">
          <img class="logo" :src="LogoFB" alt="Fondation Beyeler" />
        </a>
      </div>
    </div>
  </footer>
</template>

<style lang="scss" scoped>
@use "@/style/abstracts/responsive";
@use "@/style/base/typo";
@use "@/style/elements/button";

.scene-footer {
  margin: 5rem 0;

  > * {
    display: flex;
    flex-direction: column;
  }

  > .content {
    padding: 0 var(--grid-gap);
    max-width: var(--content-max-width);
    width: 100%;
    height: 100%;
    margin: auto;
  }

  .header {
    @include typo.nav-aux;
    display: flex;
    align-items: center;
    justify-content: center;
    margin: var(--grid-gap) 0;
  }

  .button-group {
    @include button.group;
  }

  .footer {
    display: grid;
    grid-template-columns: 1fr;
    grid-gap: var(--grid-gap);

    @include responsive.bp-large-up {
      grid-template-columns: 1fr 1fr;
    }
  }

  .exhibition {
    order: 0;

    @include responsive.bp-large-up {
      order: 1;
    }

    .header {
      margin: var(--grid-gap) 0 0;
    }

    .content {
      margin: var(--grid-gap) 0 0;

      &.has-extra-gap {
        margin-top: calc(var(--grid-gap) + 48px);
      }
    }
  }

  .credits {
    @include typo.meta-aux;
    order: 1;

    @include responsive.bp-large-up {
      order: 0;
    }

    .header {
      justify-content: flex-start;
    }

    .work-credits {
      display: flex;
      flex-direction: column;
    }

    .aux-credits,
    .image-credits {
      margin-top: calc(2 * var(--grid-gap));
    }
  }

  .footer-brand {
    padding: calc(5 * var(--grid-gap)) 0;
    display: flex;
    flex-direction: column;

    .brand {
      padding: var(--grid-gap) 0;
      max-width: 220px;
      margin: auto;

      @include responsive.bp-large-up {
        padding: var(--grid-gap) 0 0;
        max-width: 256px;
      }

      .logo {
        width: 100%;
      }
    }
  }
}
</style>
