cfb:
  address:
    name: Fondation Beyeler
    department-digital: Digitale Kommunikation
    street: Baselstrasse 77
    po: "4125"
    city: Riehen/Basel
    country: Schweiz
link:
  cfb-privacy: https://www.fondationbeyeler.ch/datenschutzerklaerung
  cfb-site: https://www.fondationbeyeler.ch/
  cfb-facebook: https://de-de.facebook.com/FondationBeyeler/
  cfb-youtube: https://www.youtube.com/channel/UCVKuxYFkse6gaNn0ri2Il0w
  cfb-instagram: https://www.instagram.com/fondationbeyeler/
  cfb-twitter: https://twitter.com/fond_beyeler
  cfb-newsletter: https://www.fondationbeyeler.ch/medien/newsletter
menu:
  home: Startseite
  collection: Sammlung
  menu: Menu
  information: Über
  catalog: Werkliste
  imprint: Impressum
  privacy: Datenschutzerklärung
  to-museum: Zur Museumswebseite
navigate:
  previous: Zurück
  next: Vorwärts
work-switch:
  text: Text
  image: Bild
player:
  play: Play
  stop: Stop
  pause: Pause
  resume: Play
work:
  title-credits: Credits
  title-share: Share
  button-share: Seite teilen
  current-exhibition: Zur aktuellen Ausstellung
  view-on-site: Zum Sammlungskatalog
rich-media:
  shop: Zum Art Shop
tutorial:
  story: Um welches Werk handelt es sich?
  audio: Entdecken Sie die Highlights der Sammlung Beyeler. Einfach Text anklicken oder Audio abspielen.
inline-content-about:
  collection-title: Die Sammlung der Fondation Beyeler
  collection: Die Sammlung Beyeler bildet den eigentlichen Kern der Fondation Beyeler sowie deren inhaltlichen Ausgangspunkt. Über 50 Jahre trug das Sammler- und Galeristenehepaar Hildy und Ernst Beyeler ausgesuchte Werke der modernen Kunst mit herausragender Qualität zusammen. Um ihre Sammlung der Öffentlichkeit zugänglich zu machen, gründeten sie eine Stiftung. 1997 eröffneten sie in ihrem Wohnort Riehen in der Nähe von Basel die Fondation Beyeler in dem eigens dafür entworfenen Museumsbau von Renzo Piano. An diesem Ort verbinden sich Kunst, Natur und Architektur zu einer einzigartigen sinnlichen Erfahrung. Seit dem Tod des Stifterehepaars wird die Sammlung vornehmlich im Bereich der zeitgenössischen Kunst weitergeführt. Dabei stehen vermehrt auch Werke von Künstlerinnen im Fokus, die bislang in der Sammlung untervertreten waren.
  collection-online-title: Die Sammlung der Fondation Beyeler online
  collection-online: Anlässlich des 25-jährigen Jubiläums der Fondation Beyeler werden unter dem Titel «Beyeler Collection Stories» ausgewählte Highlights der Sammlung online zugänglich und erlebbar gemacht. Im Mittelpunkt der digitalen Inszenierung stehen die Geschichten rund um die einzelnen Werke und Künstler:innen, die hier gebündelt werden. Angereichert mit interaktiven Medien und Hintergründen aus 25 Jahren Fondation Beyeler gewähren die Inhalte einen spielerischen ersten Einblick in die Sammlung und laden zugleich zu einer vertieften Auseinandersetzung mit ihr ein. Die «Beyeler Collection Stories» befinden sich noch im Aufbau. Sie ergänzen den bestehenden wissenschaftlichen «Online Sammlungskatalogs» der Fondation Beyeler.
  social-title: Auf dem Laufenden bleiben
page:
  imprint:
    title: Impressum
    subtitle-content: Verantwortlich für Inhalt
    subtitle-design: Design
    subtitle-code: Technische Umsetzung
    subtitle-photography: Fotografie
    content-photography: Robert Bayer, Peter Schibli, Kurt Wyss, u.a.
    subtitle-text: Texterstellung
    subtitle-editing: Redaktion und Lektorat
    notes: Trotz intensiver Bemühungen ist es nicht in allen Fällen gelungen, Urheberschaft und Herkunft aller Abbildungen zu klären. Berechtigte Ansprüche werden bei entsprechendem Nachweis im Rahmen der üblichen Honorarvereinbarungen abgegolten.
  notfound:
    title: Seite nicht gefunden
    home: Zurück zur Startseite
