<script lang="ts">
import { defineComponent, onMounted, ref } from "vue";
import { useI18n } from "vue-i18n";
import LogoFB from "@/assets/image/logo-fb.svg";
import { storeToRefs } from "pinia";
import { useScrollLock } from "@vueuse/core";
import { useUiStore } from "@/stores/ui";

export default defineComponent({
  setup() {
    const el = ref<HTMLElement | null>(null);
    const { t } = useI18n();
    const { displaySplash } = storeToRefs(useUiStore());
    const isLocked = useScrollLock(el);

    onMounted(() => {
      isLocked.value = true;
      setTimeout(() => {
        isLocked.value = false;
        displaySplash.value = false;
      }, 2000);
    });

    return {
      t,
      el,
      LogoFB,
      displaySplash,
    };
  },
});
</script>

<template>
  <transition name="splash" :duration="3500" appear>
    <div v-if="displaySplash" ref="el" class="splash">
      <div class="content">
        <div class="title">
          <div>Beyeler</div>
          <div>Collection</div>
          <div>Stories</div>
        </div>
        <div class="brand">
          <img class="logo" :src="LogoFB" alt="Fondation Beyeler" />
        </div>
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@use "@/style/abstracts/responsive";
@use "@/style/base/typo";

.splash {
  position: fixed;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  height: 100%;
  width: 100%;
  background-color: rgb(var(--c-bg));
  z-index: var(--splash-z-index);
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: none;

  .title,
  .brand {
    padding: var(--grid-gap) 0;
    @include responsive.bp-large-up {
      padding: calc(1.5 * var(--grid-gap)) 0;
    }
    @include responsive.bp-x-large-up {
      padding: calc(2 * var(--grid-gap)) 0;
    }
  }

  .title {
    @include typo.title;

    text-transform: uppercase;
    line-height: 0.8;
    font-variation-settings: "wght" 900;

    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }

  .brand {
    img {
      height: 24px;

      @include responsive.bp-large-up {
        height: 36px;
      }
      @include responsive.bp-x-large-up {
        height: 54px;
      }
    }
  }
}

/* splash transitions */
.splash-enter-active {
  .content {
    transition: opacity 1000ms ease-out;
  }
  .title {
    transition: opacity 250ms ease-out 1000ms, transform 250ms ease-out 1000ms,
      font-variation-settings 500ms ease-out 1100ms;
  }
  .brand {
    transition: transform 250ms ease-out 1000ms;
  }
}
.splash-enter-from {
  .content {
    opacity: 0;
    transition: opacity 1000ms ease-out;
  }
  .title {
    font-variation-settings: "wght" 100;
    opacity: 0;
  }
  .brand {
    transform: translateY(-78px);

    @include responsive.bp-x-large-up {
      transform: translateY(-112px);
    }

    @include responsive.bp-x-large-up {
      transform: translateY(-159px);
    }
  }
}
.splash-enter-to {
  .content {
    opacity: 1;
  }
  .title {
    transform: translateY(0);
    font-variation-settings: "wght" 900;
    opacity: 1;
  }
  .brand {
    transform: translateY(0);
  }
}
.splash-leave-active {
  transition: opacity 1000ms ease-out 1500ms;

  .title {
    transition: opacity 350ms ease-out 1000ms, transform 350ms ease-out 1000ms;
  }

  .brand {
    transition: opacity 350ms ease-out 1000ms, transform 350ms ease-out 1000ms;
  }
}
.splash-leave-from {
  opacity: 1;
  .title {
    transform: translateY(0);
  }
  .brand {
    transform: translateY(0);
  }
}
.splash-leave-to {
  opacity: 0;

  .title {
    opacity: 0;
    transform: translateY(-10vh);
  }

  .brand {
    opacity: 0;
    transform: translateY(10vh);
  }
}
</style>
