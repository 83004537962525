<script lang="ts">
import { defineComponent, ref, watch, onMounted } from "vue";
import { useDevice } from "@/composables/device";
import eventBus from "@/eventBus";
import { useViewportFocus } from "@/composables/ui";

export default defineComponent({
  inheritAttrs: false,
  components: {},
  props: {
    lookup: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const root = ref<HTMLElement | null>(null);
    const title = ref("");
    const { isMobile } = useDevice();
    const { inFocus } = useViewportFocus(root);

    const onClick = () => {
      const e = {
        title: title.value,
        lookup: props.lookup,
      };
      eventBus.emit("objRef:show", e);
    };

    onMounted(() => {
      title.value = root.value?.innerText ?? "";
    });

    //hide panel for objRefs out of focus (Desktop only)
    watch(
      () => inFocus.value,
      (value) => {
        if (!value && !isMobile.value) {
          eventBus.emit("objRef:hide");
        }
      }
    );

    return {
      root,
      onClick,
      inFocus,
    };
  },
});
</script>

<template>
  <div ref="root" :title="lookup" class="obj-ref" :class="[{ 'in-focus': inFocus }]">
    <div @click="onClick" ref="content">
      <slot name="default" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/style/base/typo";
@use "@/style/elements/button";

.obj-ref {
  --focus-value: 0;

  display: inline-flex;
  cursor: pointer;
  position: relative;

  &.in-focus {
    --focus-value: 1;
  }

  &:after {
    transition-property: width, background-color;
    transition-duration: 200ms;
    transition-timing-function: ease-out;
    content: "";
    width: calc(100% * var(--focus-value));
    position: absolute;
    left: 0;
    height: 100%;
    background: rgb(var(--c-primary));
    border-radius: var(--border-radius);
    z-index: -1;
  }

  pre {
    font-size: 30px;
    background-color: var(--c-primary);
  }

  > div {
    padding: 0 0.25rem;
  }
}
</style>
