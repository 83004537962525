<script lang="ts">
import { defineComponent, computed, h } from "vue";
import Artist from "./Artist.vue";
import WorkAudio from "./WorkAudio.vue";
import WorkImage from "./WorkImage.vue";
import Product from "./Product.vue";
import WorkText from "./WorkText.vue";
import WorkVideo from "./WorkVideo.vue";

const NoComponent = defineComponent({
  render() {
    return h("div", {});
  },
});

const parseCtUid = (lookup: string): { ct: string; uid: string } => {
  const re = /(?<ct>[a-z-_.]+):(?<uid>[0-9A-F]{8})/;
  const match = lookup.match(re);
  if (match?.groups) {
    const { ct, uid } = match.groups;
    return { ct, uid };
  }
  return { ct: "", uid: "" };
};

export default defineComponent({
  props: {
    lookup: {
      type: String,
      required: true,
    },
  },
  setup(props) {
    const objCt = computed(() => {
      const { ct } = parseCtUid(props.lookup);
      return ct;
    });

    const objUid = computed(() => {
      const { uid } = parseCtUid(props.lookup);
      return uid;
    });

    const component = computed(() => {
      if (objCt.value === "catalog.artist") {
        return Artist;
      }
      if (objCt.value === "catalog.workaudio") {
        return WorkAudio;
      }
      if (objCt.value === "catalog.workimage") {
        return WorkImage;
      }
      if (objCt.value === "foundation.product") {
        return Product;
      }
      if (objCt.value === "catalog.worktext") {
        return WorkText;
      }
      if (objCt.value === "catalog.workvideo") {
        return WorkVideo;
      }
      return NoComponent;
    });

    return {
      component,
      objUid,
    };
  },
});
</script>

<template>
  <div class="rich-media">
    <div class="rich-media-container">
      <component :is="component" :uid="objUid" class="rich-media-content" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/style/base/typo";
@use "@/style/abstracts/responsive";

.rich-media {
  height: 100%;

  .rich-media-container {
    height: 100%;
  }
  .rich-media-content {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    height: 100%;
  }

  :deep(.body) {
    //background-color: red;
    @include typo.content-alt-aux;
  }

  :deep {
    p {
      &:after {
        display: block;
        content: "\a";
        white-space: pre;
      }
    }
  }

  :deep(.credits) {
    @include typo.content-alt-aux;

    padding-bottom: var(--grid-gap);
  }

  :deep(.visual),
  :deep(.video),
  :deep(.audio-player) {
    margin-bottom: var(--grid-gap);
  }

  :deep {
    a {
      width: 100%;
      margin-top: auto;

      @include responsive.bp-large-up {
        margin-top: initial;
      }
    }
  }
}
</style>
