import type { Ref } from "vue";
// import { computed } from "vue";
import { useTitle } from "@vueuse/core";

const useDocumentTitle = (title: Ref<string>) => {
  // const fullTitle = computed(() => {
  //   return `FB - ${title.value}`;
  // });
  useTitle(title, {
    titleTemplate: "%s | Fondation Beyeler",
  });
  return {
    title,
  };
};

export { useDocumentTitle };
