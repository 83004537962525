import { defineStore } from "pinia";

interface State {
  scrollContainerHeight: number;
  scrollY: number;
  scrollLock: boolean;
  scrollUp: boolean;
  scrollDown: boolean;
  scrollBarWidth: number;
  isScrolling: boolean;
  isScrollWheel: boolean;
  modalOpen: boolean;
  displaySplash: boolean;
}

export const useUiStore = defineStore("ui", {
  state: (): State => ({
    scrollContainerHeight: 0,
    scrollY: 0,
    scrollLock: false,
    scrollUp: false,
    scrollDown: false,
    scrollBarWidth: 0,
    isScrolling: false,
    isScrollWheel: false,
    modalOpen: false,
    displaySplash: true,
  }),
  actions: {
    setScrollY(value: number) {
      this.scrollY = value;
    },
    setScrollContainerHeight(value: number) {
      this.scrollContainerHeight = value;
    },
    setScrollLock(value: boolean) {
      this.scrollLock = value;
    },
    setScrollUp(value: boolean) {
      this.scrollUp = value;
    },
    setScrollDown(value: boolean) {
      this.scrollDown = value;
    },
    setScrollBarWidth(value: number) {
      this.scrollBarWidth = value;
    },
    setIsScrolling(value: boolean) {
      this.isScrolling = value;
    },
    setScrollWheel(value: boolean) {
      this.isScrollWheel = value;
    },
  },
});
