<script lang="ts">
import { computed, defineComponent, ref, onMounted, watch } from "vue";
import { useI18n } from "vue-i18n";
import type { PropType } from "vue";
import type { Work } from "@/typings/api";
import { useElementSize } from "@vueuse/core";
import { useViewportFocus } from "@/composables/ui";
import { splitLines } from "@/utils/text/split";
import { mapRange } from "@/composables/helper";
import { useDevice } from "@/composables/device";
import { useRouter } from "vue-router";

const MAX_WEIGHT = 900;
const MAX_CHAR = 80;

export default defineComponent({
  components: {},
  props: {
    work: {
      type: Object as PropType<Work>,
      required: true,
    },
  },
  setup(props) {
    const { isMobile } = useDevice();
    const { t, locale: i18nLocale } = useI18n();
    const locale = computed(() => {
      // TODO: handle default language
      return i18nLocale.value === "de" ? "" : i18nLocale.value;
    });
    const router = useRouter();
    const el = ref<HTMLElement | null>(null);
    /*    const { scrollOffset } = useDeviceExtra();
    const relScrollY = computed(() => {
      return scrollY.value + scrollOffset.value / 2;
    });*/
    const { inFocus, focusValue, focusDirection } = useViewportFocus(el);

    const hasAudio = computed(() => !!(props.work.audios && props.work.audios.length));
    const playAudio = () => {};
    const lines = ref([]);
    const ghostEl = ref<HTMLElement | null>(null);
    const { width: ghostWidth, height: ghostHeight } = useElementSize(ghostEl);

    const fontSize = computed(() => {
      const numChars = teaser.value.length ?? 0;
      if (isMobile.value) {
        return mapRange(MAX_CHAR - numChars, [0, MAX_CHAR], [5, 7]);
      }
      return mapRange(MAX_CHAR - numChars, [0, MAX_CHAR], [8, 14]);
    });

    const textStyleMax = computed(() => {
      return {
        fontSize: `${fontSize.value}vh`,
        fontVariationSettings: `"wght" ${MAX_WEIGHT}`,
      };
    });

    const textStyle = computed(() => {
      const weight = Math.round(100 + (MAX_WEIGHT - 100) * focusValue.value);
      const scale = 1 - 0.2 * (1 - focusValue.value);
      const lineHeight = 0.9 + 1.6 * (1 - focusValue.value);
      return {
        fontSize: `${fontSize.value}vh`,
        fontVariationSettings: `"wght" ${weight}`,
        transform: `scale(${scale})`,
        lineHeight,
      };
    });

    const teaserStyle = computed(() => {
      let translateY = -50;
      if (focusDirection.value == "out") {
        translateY = -70 + focusValue.value * 20;
      } else {
        translateY = -30 - focusValue.value * 20;
      }
      return {
        transform: `translateY(${translateY}%)`,
      };
    });

    const isActive = computed(() => {
      return focusValue.value === 1;
    });

    const teaser = computed(() => {
      return props.work.teaser ? `${props.work.teaser.substring(0, MAX_CHAR)}` : "";
    });

    const updateLines = () => {
      lines.value = splitLines(ghostEl.value);
    };

    watch(
      () => ghostWidth.value * ghostHeight.value,
      () => {
        updateLines();
      }
    );

    onMounted(() => {
      setTimeout(() => {
        updateLines();
      }, 50);
    });

    const navigate = () => {
      if (inFocus.value) {
        router.push({
          name: "workDetail",
          params: {
            locale: locale.value,
            uid: props.work.uid,
            transition: "reveal",
          },
        });
      }
    };

    return {
      t,
      locale,
      el,
      ghostEl,

      hasAudio,
      playAudio,

      inFocus,
      isActive,

      teaser,
      lines,
      textStyleMax,
      textStyle,
      teaserStyle,

      navigate,
    };
  },
});
</script>

<template>
  <transition name="fade">
    <div
      ref="el"
      v-if="work"
      class="text-work"
      :class="[{ 'in-focus': inFocus }, { 'is-active': isActive }]"
    >
      <a v-touch:tap="navigate" @click.prevent :style="teaserStyle">
        <div class="teaser" :style="textStyle">
          <div v-for="(line, index) in lines" :key="`${work.uid}-line-${index}`" v-text="line" />
        </div>
      </a>
      <div ref="ghostEl" class="ghost">
        <div data-split class="teaser" :style="textStyleMax" v-html="teaser" />
      </div>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@use "@/style/abstracts/responsive";

.text-work {
  height: calc(var(--vh) * 50);
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;
  text-transform: uppercase;
  //border-bottom: 1px solid red;

  * {
    backface-visibility: hidden;
  }

  .teaser {
    transform: scale(1);
  }

  > a {
    position: absolute;
    top: 50%;
    transform: translateY(-70%);

    @include responsive.bp-small {
      padding-bottom: 45px;
    }
  }

  &.is-active {
    .teaser {
      transform: scale(1);
      transition: transform 350ms ease-out;
      @include responsive.on-hover {
        transform: scale(1.05) !important;
      }
    }
  }

  &.in-focus {
    > a {
      transform: translateY(-50%);
    }

    + .text-work {
      > a {
        transform: translateY(-30%);
      }
    }
  }

  a,
  .ghost {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    width: 100%;
    .teaser {
      text-align: center;
    }
    @include responsive.bp-x-large-up {
      max-width: var(--content-max-width);
      letter-spacing: 0.15rem;
    }
  }
  .ghost {
    position: absolute;
    opacity: 0;
    z-index: -1;
    .teaser {
      color: deepskyblue;
    }
  }
}

/* slide-top transitions */
.fade-enter-active,
.fade-leave-active {
  transition-delay: 100ms;
  transition-property: opacity;
  transition-duration: 250ms;
  transition-timing-function: ease-in-out;
}
.fade-enter-from,
.fade-leave-to {
  opacity: 0;
}
.fade-enter-to {
  opacity: 1;
}
</style>
