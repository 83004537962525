import "vue-router";
import { createRouter, createWebHistory } from "vue-router";
import { storeToRefs } from "pinia";
import { isEqual } from "lodash-es";
import { useCatalogStore } from "@/stores/catalog";
import Base from "@/views/Base.vue";
import NotFound from "@/views/NotFound.vue";
import Navigation from "@/components/navigation/Navigation.vue";
import StoryFirst from "@/views/catalog/StoryFirst.vue";
import ArtworkFirst from "@/views/catalog/ArtworkFirst.vue";
import WorkDetail from "@/views/catalog/WorkDetail.vue";
import PageImprint from "@/views/page/PageImprint.vue";
import Tutorial from "@/components/introduction/Tutorial.vue";
//
import CharMap from "@/views/ptype/CharMap.vue";
import LineSplit from "@/views/ptype/LineSplit.vue";
import VPFocus from "@/views/ptype/VPFocus.vue";
import { useUiStore } from "@/stores/ui";
import eventBus from "@/eventBus";

declare module "vue-router" {
  interface RouteMeta {
    transition?: string;
  }
}

const routes = [
  {
    path: "/:locale([a-z]{2})?/",
    name: "Home",
    component: Base,
    props: (route: any) => ({ locale: route.params.locale || "de" }),
    children: [
      {
        path: "",
        name: "storyFirst",
        components: {
          default: StoryFirst,
          navigation: Navigation,
          tutorial: Tutorial,
        },
        props: {
          navigation: {
            showModeSwitch: true,
          },
          tutorial: {
            showTutorial: true,
          },
        },
      },
      {
        path: "artworks/",
        name: "artworkFirst",
        components: {
          default: ArtworkFirst,
          navigation: Navigation,
        },
        props: {
          navigation: {
            showModeSwitch: true,
          },
        },
      },
      {
        path: "work/:uid/",
        name: "workDetail",
        components: {
          default: WorkDetail,
          navigation: Navigation,
        },
        props: {
          default: true,
          navigation: {
            showWorkIndexLink: true,
          },
        },
      },
      {
        name: "imprint",
        path: "imprint/",
        components: {
          default: PageImprint,
          navigation: Navigation,
        },
        props: {
          default: true,
        },
        meta: {
          transition: "slide-right",
        },
      },
      {
        path: "ptype/char-map/",
        component: CharMap,
      },
      {
        path: "ptype/line-split/",
        component: LineSplit,
      },
      {
        path: "ptype/vp-focus/",
        component: VPFocus,
      },
      {
        path: ":pathMatch(.*)*",
        name: "NotFound",
        component: NotFound,
        props: (route: any) => ({
          path: route.path,
        }),
      },
    ],
  },
];

const router = createRouter({
  history: createWebHistory("/"),
  // @ts-ignore
  routes,
  scrollBehavior(to, from, savedPosition) {
    if (from && to.name === from.name && isEqual(to.params, from.params)) {
      return false;
    }
    return savedPosition || { left: 0, top: 0 };
  },
});

router.beforeEach((to, from, next) => {
  const { listMode } = storeToRefs(useCatalogStore());
  const { modalOpen } = storeToRefs(useUiStore());

  if (modalOpen.value) {
    modalOpen.value = false;
    eventBus.emit("objRef:hide");
  }

  if (to.name === "storyFirst") {
    listMode.value = "storyFirst";
  } else if (to.name === "artworkFirst") {
    listMode.value = "artworkFirst";
  }
  if (to.params.locale === from.params.locale) {
    next();
    return;
  }
  next();
});

export default router;
