<script lang="ts">
import { defineComponent, ref, computed } from "vue";
import { useViewportFocus } from "@/composables/ui";
import { useElementBounding } from "@vueuse/core";

export default defineComponent({
  props: {
    scrollY: {
      type: Number,
      default: 0,
    },
  },
  setup() {
    const el = ref<HTMLElement | null>(null);
    const { visible, inFocus, focusValue, elAbsY } = useViewportFocus(el);
    const { y, height } = useElementBounding(el);

    const style = computed(() => {
      return {
        background: `rgba(0, 232, 167, ${focusValue.value})`,
      };
    });

    return {
      el,
      inFocus,
      visible,
      focusValue,
      style,
      height,
      y,
      elAbsY,
    };
  },
});
</script>

<template>
  <div ref="el" class="focus-aware" :style="style">
    <pre
      v-text="{
        focusValue,
        visible,
        scrollY,
        y,
        height,
        elAbsY,
      }"
    />
  </div>
</template>

<style lang="scss" scoped>
.focus-aware {
  height: 50vh;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 12px;
  border: 1px dotted orange;
  scroll-snap-align: center;
  scroll-snap-stop: normal;

  &:first-child {
    margin-top: 25vh;
  }
}
</style>
