<script lang="ts">
import type { Work } from "@/typings/api";
import type { PropType } from "vue";
import { computed, defineComponent } from "vue";
import { useShare } from "@vueuse/core";
import Button from "@/components/ui/button/Button.vue";
import SocialShare from "@/components/share/SocialShare.vue";
import { useI18n } from "vue-i18n";

export default defineComponent({
  components: {
    Button,
    SocialShare,
  },
  props: {
    work: {
      type: Object as PropType<Work>,
      required: true,
    },
  },
  setup(props) {
    const { t } = useI18n();
    const shareOptions = computed(() => {
      return {
        title: props.work.title,
        text: props.work.teaser,
        url: location.href,
      };
    });
    // NOTE: only works over https:
    //       https://developer.mozilla.org/en-US/docs/Web/API/Navigator/share
    const { share: nativeShare, isSupported: nativeShareSupported } = useShare(shareOptions);
    const startShare = () => {
      nativeShare();
    };
    return {
      t,
      nativeShareSupported,
      startShare,
    };
  },
});
</script>
<template>
  <div class="share">
    <div v-if="nativeShareSupported">
      <Button @click="startShare" :label="t('work.button-share')" class="invert" />
    </div>
    <div v-else class="button-group">
      <SocialShare network="twitter" :title="work.title">
        <Button label="Twitter" class="invert" />
      </SocialShare>
      <SocialShare network="facebook" :title="work.title">
        <Button label="Facebook" class="invert" />
      </SocialShare>
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/style/elements/button";

.button-group {
  @include button.group;
}
</style>
