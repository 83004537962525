<script lang="ts">
import { defineComponent } from "vue";
import { useViewportFocus } from "@/composables/ui";
import FocusAware from "@/components/dev/FocusAware.vue";

export default defineComponent({
  components: {
    FocusAware,
  },
  setup() {
    const { focusStart, focusCenter, focusEnd } = useViewportFocus();

    return {
      focusStart,
      focusCenter,
      focusEnd,
      scrollY,
    };
  },
});
</script>

<template>
  <div class="ptype">
    <div class="scroll-panel">
      <FocusAware />
      <FocusAware />
      <FocusAware />
    </div>
    <div class="focus-debug">
      <div class="line line--start" :style="{ top: `${focusStart}px` }" />
      <div class="line line--center" :style="{ top: `${focusCenter}px` }" />
      <div class="line line--end" :style="{ top: `${focusEnd}px` }" />
      <div class="area" :style="{ top: `${focusStart}px`, height: `${focusEnd - focusStart}px` }" />
    </div>
  </div>
</template>

<style lang="scss" scoped>
@use "@/style/base/scroll";

.ptype {
  position: relative;
}
.scroll-panel {
  background: #fafafa;
  padding-bottom: 25vh;
}
.focus-debug {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  opacity: 0.5;
  pointer-events: none;
  .line {
    position: absolute;
    height: 0;
    border-bottom: 1px dotted blueviolet;
    width: 100%;
    &--center {
      border-bottom: 1px dotted darkred;
    }
  }
  .area {
    background: rgba(85, 0, 255, 0.075);
    position: absolute;
    width: 100%;
  }
}
</style>
