// @ts-ignore
import Splitting from "splitting";

const splitLines = (el: HTMLElement | null) => {
  if (!el) {
    return [];
  }
  // Splitting modifies the dom - so we have to take a snapshot to restore later on
  const before = el.innerHTML;
  const splitEl = el.querySelector("[data-split]");
  const raw = Splitting({ target: splitEl, by: "lines" });
  // restore to original content
  el.innerHTML = before;
  if (!raw.length) {
    return [];
  }
  return raw[0].lines.map((l: Array<any>) => {
    return l.map((w: any) => w.innerHTML).join(" ");
  });
};

export { splitLines };
