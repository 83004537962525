<script lang="ts">
import { computed, defineComponent, ref, watch } from "vue";
import { useI18n } from "vue-i18n";
import { useRoute } from "vue-router";
import { round } from "lodash-es";
import { Howl } from "howler";
import {
  refDebounced,
  TransitionPresets,
  useRafFn,
  useTransition,
  watchDebounced,
} from "@vueuse/core";
import { storeToRefs } from "pinia";
import { useCatalogStore } from "@/stores/catalog";
import eventBus from "@/eventBus";
import Duration from "@/components/ui/time/Duration.vue";
import AnimatedDuration from "@/components/ui/time/AnimatedDuration.vue";
import Playhead from "./Playhead.vue";
import TransitionExpand from "@/components/ui/transition/TransitionExpand.vue";
import { useUiStore } from "@/stores/ui";

const FOCUS_DEBOUNCE = 400;

enum State {
  STOPPED = "stopped",
  PLAYING = "playing",
  PAUSED = "paused",
}

export default defineComponent({
  components: {
    TransitionExpand,
    Duration,
    AnimatedDuration,
    Playhead,
  },
  setup() {
    const { t } = useI18n();
    const { focusedWork } = storeToRefs(useCatalogStore());
    const work = refDebounced(focusedWork, FOCUS_DEBOUNCE);
    const audios = computed(() => work.value?.audios ?? []);
    const audio = computed(() => (audios.value.length ? audios.value[0] : null));
    const audioUid = computed(() => (audio.value ? audio.value.uid : null));
    const audioCredits = computed(() => (audio.value ? audio.value.credits : ""));

    const player = ref<Howl | null>(null);
    const autoplay = ref(false);
    const playerAudioUid = ref("");
    const playerState = ref(State.STOPPED);
    const debouncedPlayerState = ref(State.STOPPED);
    const playerPosition = ref(0);
    const playerDuration = ref(-1);

    // TODO: needs to be re-implemented
    const route = useRoute();
    const routeName = computed(() => route.name);
    const isVisible = computed(() => {
      if (routeName.value === "artworkFirst") {
        return false;
      }
      return !!audio.value;
    });
    watch(
      () => routeName.value,
      (value) => {
        if (value === "artworkFirst") {
          pause(false);
        }
        /*
        if (value === "storyFirst" && autoplay.value && audio.value) {
          if (playerState.value === State.PAUSED) {
            resume()
          } else {
            play()
          }
        }
        */
      }
    );

    const { isScrolling } = storeToRefs(useUiStore());

    watchDebounced(
      playerState,
      (value: State) => {
        debouncedPlayerState.value = value;
      },
      {
        debounce: 100,
        maxWait: 200,
      }
    );

    const creditsVisible = ref(false);
    const toggleCredits = () => {
      creditsVisible.value = !creditsVisible.value;
    };

    watch(
      () => isScrolling.value,
      () => {
        creditsVisible.value = false;
      }
    );

    const duration = computed(() => {
      // return actual value from player, else pre-calculated value from API
      if (playerDuration.value > -1) {
        return playerDuration.value;
      }
      return audio.value ? audio.value.duration : 0;
    });

    const timeLeft = computed(() => {
      if (!duration.value) {
        return 0;
      }
      return duration.value - playerPosition.value;
    });

    const animatedTimeLeft = useTransition(timeLeft, {
      duration: 500,
      transition: TransitionPresets.easeInOutCubic,
    });

    const progress = computed(() => {
      if (!playerDuration.value) {
        return 0;
      }
      return round(playerPosition.value / playerDuration.value, 3);
    });

    const updatePlayerState = () => {
      if (!player.value) {
        return;
      }
      playerDuration.value = player.value.duration();
      playerPosition.value = round(player.value.seek(), 3);
    };

    const play = (relPos = 0) => {
      if (!audio.value) {
        return;
      }
      const src = `/media/${audio.value.path}`;
      if (player.value) {
        player.value.unload();
      }
      playerAudioUid.value = audio.value?.uid ?? "";
      const p = new Howl({
        src: src,
        html5: true,
        format: ["mp3"],
      });
      p.on("play", () => {
        playerState.value = State.PLAYING;
      });
      p.on("stop", () => {
        playerState.value = State.STOPPED;
      });
      p.on("pause", () => {
        playerState.value = State.PAUSED;
      });
      p.on("end", () => {
        playerState.value = State.STOPPED;
        playerPosition.value = 0;
        playerDuration.value = -1;
      });
      p.play();
      p.once("play", () => {
        if (relPos) {
          const d = audio.value?.duration ?? 0;
          const absPos = d * relPos;
          p.seek(absPos);
        }
      });
      player.value = p;
      autoplay.value = true;
    };

    const pause = (all = true) => {
      if (player.value && playerState.value === State.PLAYING) {
        player.value.pause();
      }
      if (all) {
        autoplay.value = false;
      }
    };

    const resume = () => {
      if (player.value) {
        player.value.play();
      }
    };

    const stop = (all = false) => {
      if (player.value) {
        // player.value.stop();
        player.value.fade(1, 0, 100);
        setTimeout(() => {
          if (player.value) {
            player.value.stop();
            player.value.volume(1);
          }
          playerPosition.value = 0;
          playerDuration.value = -1;
        }, 100);
        playerAudioUid.value = "";
      }
      if (all) {
        autoplay.value = false;
      }
    };

    const seek = async (to: number) => {
      const relPos = to;
      const absPos = playerDuration.value * to;
      if (playerState.value === State.STOPPED) {
        play(relPos);
      } else if (playerState.value === State.PAUSED) {
        if (player.value) {
          player.value.play();
          player.value.once("play", () => {
            if (player.value) {
              player.value.seek(absPos);
            }
          });
        }
      } else if (player.value) {
        player.value.seek(absPos);
      }
    };

    const { pause: pausePlayerUpdate, resume: resumePlayerUpdate } = useRafFn(() => {
      updatePlayerState();
    });

    watch(
      () => playerState.value,
      (value) => {
        if (value === State.PLAYING) {
          resumePlayerUpdate();
          eventBus.emit("audioPlayer:startsPlaying");
        } else {
          pausePlayerUpdate();
        }
      }
    );

    watch(
      () => audioUid.value,
      (value) => {
        creditsVisible.value = false;
        if (value && playerState.value === State.PAUSED) {
          stop();
          return;
        }
        if (!value && playerState.value === State.PLAYING) {
          stop();
          return;
        }
        if (autoplay.value && value) {
          if (value !== playerAudioUid.value) {
            play();
          }
        }
      }
    );

    // stop if inline player starts
    eventBus.on("inlineAudioPlayer:startsPlaying", () => pause(false));

    // stop if video player starts
    eventBus.on("videoPlayer:startsPlaying", () => pause(false));

    return {
      t,
      State,
      audio,
      isVisible,
      playerState,
      debouncedPlayerState,
      progress,
      duration,
      timeLeft,
      animatedTimeLeft,
      play,
      pause,
      resume,
      seek,
      audioCredits,
      creditsVisible,
      toggleCredits,
    };
  },
});
</script>

<template>
  <transition name="slide">
    <div v-if="isVisible" class="audio-player">
      <div class="player">
        <div class="controls controls--left">
          <div class="actions">
            <transition name="actions" mode="out-in">
              <div
                v-if="debouncedPlayerState === State.STOPPED"
                @click.prevent="play(0)"
                class="action action--play"
                v-text="t('player.play')"
              />
              <div
                v-else-if="debouncedPlayerState === State.PAUSED"
                @click.prevent="resume"
                class="action action--resume"
                v-text="t('player.resume')"
              />
              <div
                v-else-if="debouncedPlayerState === State.PLAYING"
                @click.prevent="pause(true)"
                v-text="t('player.pause')"
                class="action action--pause"
              />
            </transition>
          </div>
        </div>
        <div class="playhead">
          <Playhead @seek="seek" :progress="progress" :can-seek="playerState !== State.STOPPED" />
        </div>
        <div class="controls controls--right">
          <Duration
            v-if="playerState === State.PLAYING"
            class="position"
            :seconds="timeLeft"
            prefix="-"
          />
          <AnimatedDuration v-else class="position" :seconds="timeLeft" prefix="-" />
          <div class="actions">
            <div v-if="audioCredits" @click="toggleCredits" class="action action--info">Info</div>
            <div v-else class="action action--info action--disabled">Info</div>
          </div>
        </div>
      </div>
      <TransitionExpand :duration="200">
        <div v-if="creditsVisible && audioCredits" class="credits">
          <div class="content" v-text="audioCredits" />
        </div>
      </TransitionExpand>
    </div>
  </transition>
</template>

<style lang="scss" scoped>
@use "@/style/abstracts/responsive";
@use "@/style/base/typo";

.audio-player {
  position: fixed;
  background: rgb(var(--c-interface-bg));
  bottom: 0;
  width: 100%;
  z-index: var(--audio-player-z-index);
  @include responsive.bp-medium-up {
    bottom: 0;
  }
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  .player {
    @include typo.content-alt;

    min-height: var(--audio-player-height);
    padding: 0 var(--grid-gap);
    display: grid;
    grid-column-gap: var(--grid-gap);
    grid-template-columns: 40px 1fr auto;
    align-items: center;
    width: 100%;
    max-width: var(--content-max-width);

    @include responsive.bp-large-up {
      grid-template-columns: 65px 1fr auto;
    }

    @include responsive.bp-x-large-up {
      grid-template-columns: 80px 1fr auto;
    }

    .controls {
      .actions {
        .action {
          cursor: pointer;
          text-transform: uppercase;
          &--disabled {
            opacity: 0.5;
            cursor: default;
          }
          @include responsive.on-hover {
            opacity: 0.8;
          }
        }
      }
      .duration {
        padding: 0 var(--grid-gap);
      }
      &--right {
        display: grid;
        grid-template-columns: 80px auto;
        @include responsive.bp-large-up {
          grid-template-columns: 120px auto;
        }
      }
    }
  }
  .credits {
    width: 100%;
    border: var(--border-size) solid rgb(var(--c-border));

    .content {
      @include typo.meta;
      padding: var(--grid-gap);
      margin: auto;
      max-width: var(--content-max-width);
    }
  }
}

// detail information
.slide-enter-active,
.slide-leave-active {
  transition: transform 250ms;
}
.slide-enter-from {
  transform: translate(0, 100px);
}
.slide-leave-to {
  transform: translate(0, 100px);
}

// actions
.actions-enter-active,
.actions-leave-active {
  transition: opacity 150ms;
  opacity: 1;
}
.actions-enter-from,
.actions-leave-to {
  opacity: 0;
}
</style>
