cfb:
  address:
    name: Fondation Beyeler
    department-digital: Digital Communication
    street: Baselstrasse 77
    asfd: "4125"
    city: Riehen/Basel
    country: Switzerland
link:
  cfb-privacy: https://www.fondationbeyeler.ch/en/data-privacy-policy
  cfb-site: https://www.fondationbeyeler.ch/en/
  cfb-facebook: https://www.facebook.com/FondationBeyeler/
  cfb-youtube: https://www.youtube.com/channel/UCVKuxYFkse6gaNn0ri2Il0w
  cfb-instagram: https://www.instagram.com/fondationbeyeler/
  cfb-twitter: https://twitter.com/fond_beyeler
  cfb-newsletter: https://www.fondationbeyeler.ch/en/media/newsletter
menu:
  home: Home
  collection: Collection
  menu: Menu
  information: About
  catalog: List of works
  imprint: Imprint
  privacy: Privacy
  to-museum: Go to museum
navigate:
  previous: Previous
  next: Next
work-switch:
  text: Text
  image: Image
player:
  play: Play
  stop: Stop
  pause: Pause
  resume: Play
work:
  title-credits: Credits
  title-share: Share
  button-share: Share page
  current-exhibition: See current exhibition
  view-on-site: See collection catalogue
rich-media:
  shop: Go to Art Shop
tutorial:
  story: Which artwork does the text refer to?
  audio: Discover the highlights of the Beyeler Collection. Simply click on the text or play the audio.
inline-content-about:
  collection-title: About the Beyeler Collection
  collection: The Beyeler Collection stands at the very core of the Fondation Beyeler and provides its thematic point of departure. For over 50 years, the collector and gallery owner couple Hildy and Ernst Beyeler collected works of modern art of outstanding quality. In order to make their collection accessible to the public, they established a foundation. In 1997, they opened the Fondation Beyeler in their home town of Riehen, near Basel, in a museum building designed by Renzo Piano especially for this purpose. In this setting, art, nature and architecture combine to create a unique sensory experience. Since the passing of the founding couple, the collection has been continued primarily in the area of contemporary art. The focus is increasingly on works by women artists who were previously underrepresented in the collection.
  collection-online-title: The Beyeler Collection Online
  collection-online: On the occasion of the 25th anniversary of the Fondation Beyeler, selected highlights of the collection are made accessible to be experienced online. Under the title "Beyeler Collection Stories", the digital presentation focuses on the stories surrounding the individual artworks and artists, which are bundled here. Enriched with interactive media and background information from 25 years of the Fondation Beyeler, the content provides a playful insight into the collection and at the same time invites visitors to engage with it in greater depth. The "Beyeler Collection Stories" are still under development. They complement the Fondation Beyeler's existing scientific "Online Collection Catalogue".
  social-title: Stay up to date
page:
  imprint:
    title: Imprint
    subtitle-content: Responsible for content
    subtitle-design: Design
    subtitle-code: Technical Realisation
    subtitle-photography: Photography
    content-photography: Robert Bayer, Peter Schibli, Kurt Wyss among others
    subtitle-text: Text production
    subtitle-editing: Editing and Proofreading
    notes: Despite considerable effort, we have been unable to establish the copyright and origin of every image. Valid claims supported by appropriate proof will be honored in accordance with the usual fees.
  notfound:
    title: Page not found
    home: Back to home page
