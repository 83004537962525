import { defineStore } from "pinia";
import { isEqual, shuffle, sortBy, uniqWith } from "lodash-es";
import { getWorks } from "@/api/catalog";
import type { Artist, Work } from "@/typings/api";

interface State {
  works: Array<Work>;
  worksLoading: boolean;
  focusedWorkUid: string | null;
  listMode: "storyFirst" | "artworkFirst";
}

export const useCatalogStore = defineStore("catalog", {
  state: (): State => ({
    works: [],
    worksLoading: false,
    focusedWorkUid: null,
    listMode: "storyFirst",
  }),
  getters: {
    focusedWork(state) {
      if (!state.focusedWorkUid) {
        return null;
      }
      return state.works.find((w) => w.uid === state.focusedWorkUid);
    },
    focusedWorkIndex(state) {
      return state.works.findIndex((w) => w.uid === state.focusedWorkUid);
    },
    previousWork(state): Work | null {
      return state.works[this.focusedWorkIndex - 1];
    },
    nextWork(state): Work | null {
      return state.works[this.focusedWorkIndex + 1];
    },
    artists(state): Artist[] {
      const all: Artist[] = state.works.map((w: Work) => w.artist);
      const sorted = sortBy(all, [
        (a) => {
          return a.name;
        },
      ]);
      return uniqWith(sorted, isEqual);
    },
  },
  actions: {
    async loadWorks(): Promise<void> {
      if (this.worksLoading) {
        return;
      }
      if (this.works.length) {
        return;
      }
      this.worksLoading = true;
      const works = await getWorks(100);
      this.works = shuffle(works);
      if (this.works.length) {
        this.focusedWorkUid = this.works[0].uid || null;
      }
      this.worksLoading = false;
    },
  },
});
