<script lang="ts">
import { computed, defineComponent, ref } from "vue";
import type { PropType } from "vue";
import type { WorkImage as Image } from "@/typings/api";
import settings from "@/settings";

export default defineComponent({
  props: {
    image: {
      type: Object as PropType<Image>,
      required: true,
    },
    altText: {
      type: String,
      default: "Visual",
    },
    maxWidth: {
      type: Number,
      default: 2000,
    },
    resize: {
      type: String,
      default: "scale",
    },
  },
  setup(props) {
    const el = ref<HTMLElement | null>(null);
    const endpoint = settings.IMAGE_RESIZER_ENDPOINT;
    const ratio = computed(() => {
      return props.image?.ratio ?? 1;
    });

    const maxWidth = computed(() => {
      return props.maxWidth;
    });

    const maxHeight = computed(() => {
      return Math.round(maxWidth.value / ratio.value);
    });

    const src = computed(() => {
      // eg: /images/crop/80x80/catalog/work/AB7DD2F0/6308E497.jpg
      return `${endpoint}${props.resize}/${maxWidth.value}x${maxHeight.value}/${props.image?.path}`;
    });

    return {
      el,
      src,
    };
  },
});
</script>

<template>
  <img ref="el" :alt="altText" :src="src" />
</template>

<style lang="scss" scoped>
.visual {
  img {
    object-fit: contain;
    max-width: 100%;
    max-height: 100%;
    height: auto;
    width: 100%;
  }
}
</style>
